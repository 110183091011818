@import "../../../../../commonStyles.scss";

.PaymentReconcillationAssignPopup{
    .paymentDetailsContent {
        background: #F8FCFF;
        border: 1px solid $neutral-1107;
        border-radius: 5px;
        padding: 15px;
        .detail{
            font-size: 12px;
        }
        .heading{
            margin-bottom: 5px;
        }

        li {
            margin-right: 15px;
            float: left;
            min-width: 75px;
            list-style: none;

            &.paymentDetails_VendorName {
                width: 290px;
            }
        }

        .ms-TextField-fieldGroup{
            height: 27px !important;

            .ms-TextField-field {
                width: 85px;
            }
        }
    }
    .plusbtn {
        width: 35px;
        height: 38px;
        float: left;
        background-color: $light;
        color:$primary-100;
        border:1px solid $primary-100;
        padding:6px 6px;
        border-radius:4px;
        cursor: pointer;
    }
    .deletebtn {
        width: 35px;
        height: 38px;
        float: left;
        font-size: 18px;
        background-color: $light;
        color:$neutral-1009;
        border:1px solid $neutral-1009;
        padding:8px 9px;
        border-radius:4px;
        cursor: pointer;
    }
    .ms-TextField-fieldGroup{
        height: 38px !important;
    }
    .yearDropdownHeading{
        width: 13%;
    }
    .yearDropdown{
        width: 13%;
        .css-1u9des2-indicatorSeparator{
            display: none;
        }
    }
    .amountFieldHeading{
        width: 12%;
    }
    .amountField{
        width: 11%;
    }

    .ms-MessageBar {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        .ms-MessageBar-innerText {
            padding-top: 3px;
            color: $neutral-1101;
        }

        .ms-MessageBar-actions {
            margin: 3px 0;

            .ms-Button {
                min-width: inherit;
                padding: 2px 8px;
                height: 30px;
                margin-left: 0;
            }
        }
    }
    .CompletePeriod {
        justify-content: center;
    }
}
