.approval-loader {
    .ms-Spinner-circle {
        width: 45px;
        height: 45px;
        border-width: 4px;
        border-color: rgb(0, 120, 212) rgb(0, 120, 212) #fff #fff;
    }
    .ms-Spinner-label {
        font-weight: 600;
        font-size: 16px;
        color: #fff;
        margin-top: 15px;
  }
}
