@import "../../../commonStyles.scss";

.main-wrapper {
  width: 100%;
  background-color: $primary-900;

  .content-wrapper {
    overflow: hidden;
    overflow-y: auto;
    padding: 10px 15px 20px 15px;
    min-height: calc(100vh - 0px);
    width:100%;
    padding-top:130px;

    // &.home {
    //   padding-top:155px;
    // }
  }
}
.someClass {
  overflow-y: auto;
    height: 100vh;
    display: block;
    width: 100%;
}
@include mobiledevices {
  .main-wrapper {
    .content-wrapper {
      overflow-y: initial;
      padding-top:130px;

      &.home {
        padding-top:118px;
      }
    }
  }
}
