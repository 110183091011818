@import "../../../../commonStyles.scss";
.collectionTracking {
    .headerContent {
        float: left;
        width: 100%;
    }
   .cardContainer {
    background-color: #fff;
    border: 1px solid $neutral-400;
    padding: 15px;
    float: left;
    width: 100%;
    margin-bottom: 10px;
    border-radius: 6px;
    position: relative;
   }
    .ms-DocumentCard {
        margin-bottom: 10px;
        box-shadow: none;
        border: 1px solid $neutral-400;
    }
    .headerContent {
        .headertitle {
            font-family: $Typo-Bold;
            font-size: $rts-2;
            text-transform: uppercase;
            float: left;
            margin-top: 6px;
        }
        .headerTools {
            float: right;
            width: 80%;
            display: flex;
            justify-content: end;
            margin-bottom: 12px;

            @include mobiledevices {
                width: 80%;
            }

            .headerTooldropdown  {
                display: inline-block;
                margin-left: 10px;
                font-size: $rts-1;
            }

            .yearDropdown {
                width: 15%;

                @include mobiledevices {
                    width: 30%;
                }
            }

            .vendorDropdown {
                width: 20%; 
                
                @include mobiledevices {
                    width: 30%;
                }
            }

            .contractDropdown {
                width: 20%;  

                @include mobiledevices {
                    width: 30%;
                }
            }
        }
    }
  .ka-table .ka-no-data-cell{
        position: inherit;
    }
    .globalSearch {
        margin-bottom: 10px;
        margin-top: -28px;

        @include mobiledevices {
            margin-top: 0;
        }
    }

    .modalPopupMasterContainer {
        &.deleteConfirmPopup {
            .modalPopupContainer {
                width: 450px;

                @include tablet {
                    width: 300px;
                }
            }
        }

        .modalPopupContainer {
            width: calc(100% - 700px);

            @include desktop1440 {
                width: calc(100% - 500px);
            } 

            @include desktop1280 {
                width: calc(100% - 300px);
            }

            @include smalldesktop {
                width: 100%;
            }
        }
    }

    .progressField {
        float: left;
        width: 100%;

        .progressCost {
            float: right;
            width: 50%;
            text-align: right;
            font-weight: 600;
        }
        .progressBar {
            float: left;
            width: 50% !important;
        }

    }

    .expectedPaymentDate {
        float: left;
        width: 100%;
        .expectedDate {
            float: left;
            width: 50%;
        }

        .expectedTrashBtn {
            float: right;
            width: 50%;
            text-align: right;
            color: $neutral-1008;
            font-size: 15px;
        }
    }

    .deltaData {
        text-align: right;
    }
}

@mixin labelDateLeft {
    float: left;
    width: 205px;
    padding-right: 10px;

    @include tablet {
        width: 50%;
    }
}

@mixin CommentRight {
    float: left;
    width: 70%;
    padding-right: 10px;

    @include tablet {
        width: 50%;
    }
}

.addFormList {
  .formListGroup {
    float: left;
    width: calc(100% - 45px);

    .expectedPaymentDate {
       @include labelDateLeft;
    }
    .expectedComment {
       @include CommentRight;
    }
  }

  .formGrpButton {
    float:left;
    width: 45px;
    display: flex;

    button {
        min-width: 45px;

        .ms-Button-flexContainer {
            display: initial;
        }
    }

    .addButton {
        margin-right: 10px;

        .addIcon {
            font-size: 30px;
            line-height: 3px;
            font-family: auto;
        }
    }

    .outline-btn {
        margin-right: 0;
        .fa-trash {
            font-size: 18px;
        }
    }
  }
}

.addContactTable {
    .globalSearch {
        width: 245px;
        margin-right: 100px;
    }
    .ka-cell-text {
        word-wrap: break-word;
    }
}

.contactHistoryAdd {
    margin-left: 12px;
    float: right;

    @include mobiledevices {
        top: 19px;
    }

    
    .ms-Button-flexContainer {
        display: initial;
    }

    .contactHistoryAddIcon {
        position: relative;
        margin-left: 10px;

        &::after {
            font-size: 18px;
            margin-right: 5px;
            position: absolute;
            content: '+';
            left: -14px;
            top: -5px;
        }
    }
}

.formAddListRow {
    .formGrpButton {
        margin-top: 25px;
    }
}

.paymentDetailsHistory {
    margin-top: 41px;
    tr.ka-summary-row .ka-cell {
        padding: 11px 10px;
    }
}

.rebateTableFixed:has(.fixed-header) {
    max-height: none;
    overflow-y: initial;
}

.rebateTableFixed .fixed-header .ka-table-wrapper {
    // max-height: 95vh;
    max-height: 60vh;
    overflow: auto!important;
}

.rebateTableFixed .fixed-header .ka-table-wrapper .ka-thead {
    position: -webkit-sticky;
    position: sticky;
    top: -10px;
    z-index: 9;
}

.AddContactLeft {
    @include labelDateLeft;
}

.expectedComment {
    @include CommentRight;
}