@import '../../../../commonStyles.scss';

.FileNotReceivedContainer {
    max-height: 500px;
    overflow: hidden;
    overflow-y: auto;
    
    .searchBox {
        position: relative;
        width: 230px;
        float: right;
        .ms-TextField-field{
            padding-left: 28px;
        }
        .fa-search {
            cursor: pointer;
            position: absolute;
            top: 10px;
            left: 8px;
            color: $neutral-1007;
        }
    }

    .rebate-table {
        tbody {
            tr {
                td:nth-child(4) {
                    // color: $danger;
                    position: relative;
                    padding-left: 10px;
                    // &:before{
                    //     content: url(../../../assets/images/FileNotReceivedIcon.svg);
                    //     position: absolute;
                    //     // top: 14px;
                    //     left: 5px;
                    // }                   
                }
            }
        }

        .ka-cell {
            a {
                color: $primary-100;
                text-decoration: underline;
            }
        }
    }

    .ms-SearchBox {
        margin-bottom: 10px;
    }

    .notReceivedTxts {
        color: $danger;
    }

    .fileErrorTxts {
        color: $warning-102;
    }
}