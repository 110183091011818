@import "../../../../commonStyles.scss";

.SelfReportingContainer  {
    padding-bottom: 20px;
    background-color: #F2F6F7;
    
    .breadCrumContainer {

        .calenderContainer {
            position: absolute;
            right: 3px;
            top: -40px;
            float: right;
            
            .calenderBox{
                width: 155px;
                position: relative;
                .calenderIcon{
                    cursor: pointer;
                    position: absolute;
                    top: 7px;
                    right: 12px;
                }
            }
        }
    }

    .CardHeading {
        color: $primary;
        font-size: $rts-2;
        font-family: $Typo-Bold;
        line-height: inherit;
    }

    .DetailBlock {
        display: flex;
        align-items: center;

        .DetailBlock_image {
            border-radius: 100%;
            width: 40px;
            height: 40px;
            padding: 6px;
        }

        .DetailBlock_Text {
            span {
                font-size: $rts-1;
                font-family: $Typo-Regular;
            }

            p {
                font-family: $Typo-Bold;
                font-size: $rts-8;
            }
        }

        .IconBlue {
            background-color: $primary-1000;
        }

        .IconGreen {
            background-color: $secondary-500;
        }
    }

    .VendorFilesHeader {
        .SelectMonth {
            display: flex;
            float: right;

            .arrow {
                cursor: pointer;
            }

            .heading {
                padding: 3px 10px 0 10px;
                cursor: pointer;
            }
        }
    }

    .TableTopContainer {
        .ms-DocumentCard .ms-DocumentCardDetails {
            overflow: visible;
        }
    }

    .VendorFilesSection {
        display: flex;
        margin-top: 10px;


        .VendorFilesBlock {
            width: 20%;
            position: relative;

            .IndicationContainer {
                display: flex;
                align-items: center;

                .box {
                    border-radius: 2px;
                    height: 12px;
                    width: 12px;
                }

                p {
                    font-size: $rts-1;
                }

                .notReceivedtxt {
                    color: $danger;
                }

                .notReceivedIndicator {
                    background-color: $danger;
                }

                .Receivedtxt {
                    color: $info-1000;
                }

                .ReceivedIndicator {
                    background-color: $info-1000;
                }

                .Preppedtxt {
                    color: $yellow-200;
                }

                .PreppedIndicator {
                    background-color: $yellow-200;
                }

                .Processedtxt {
                    color: $secondary-800;
                }

                .ProcessedIndicator {
                    background-color: $secondary-800;
                }

                .NoSpendtxt {
                    color: $neutral-1113;
                }

                .NoSpendIndicator {
                    background-color: $neutral-1113;
                }

                .FileErrortxt {
                    color: $warning-102;
                }

                .FileErrorIndicator {
                    background-color: $warning-102;
                }
            }

            .TextContainer {
                display: flex;
                align-items: center;
                padding-top: 5px;

                p {
                    font-family: $Typo-Bold;
                    font-size: $rts-8;
                }

                img {
                    position: absolute;
                    right: 24px;
                }
            }

            &:after {
                background: $neutral-400;
                content: "";
                height: 37px;
                position: absolute;
                right: 13px;
                top: 7px;
                width: 1px;
            }

            &:last-child {
                &:after {
                    width: 0;
                }
            }
        }
    }

    @include mobiledevices {
        .VendorFilesSection {
            flex-direction: row;
            flex-wrap: wrap;

            .VendorFilesBlock {
                width: 50%;
                margin-bottom: 10px;
            }
        }

        .breadCrumContainer{
            .calenderContainer {
                top: -32px;
            }
        }
    }
    
}

.YearSelect {
    padding: 8px;
 
    .SelectYear {
        display: flex;
        align-items: center;
        justify-content: center;
 
        .arrow {
            cursor: pointer;
        }
 
        .heading {
            padding: 3px 90px 0 90px;
        }
    }
 
    .yearContainer {
        display: flex;
        flex-wrap: wrap;
        margin: 4px 10px;
 
        .yearBox {
            margin: 5px;
            border-radius: 4px;
            width: 68px;
            padding: 10px 18px;
            font-size: $rts-1;
            border: 1px solid $neutral-400;
            cursor: pointer;
        }
 
        .yearSelected {
            background: #F2F8FD;
            border: 1px solid $primary-100;
        }
    }
 
    .yearBtnContainer {
        float: right;
        padding: 0 14px 10px 0;

        .ms-Button {
            padding: 2px 8px;
            min-width: inherit;
        }
    }
}
 
.disabledYear {
	pointer-events: none;
	opacity: 0.6;
}

.disabledArrow {
	pointer-events: none;
	opacity: 0.2;
}

.ms-Callout {
&:has(.YearSelect) {
        width: 270px;
    }
}

.NotReceivedContainer {
    cursor: pointer;
}

.fileCompletionTable {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    border: 1px solid $neutral-400;
    padding: 5px;
    border-top: 5px solid $secondary-400;
    border-radius: 4px;
    font-size: 13px;

    li {
        list-style: none;
        text-align: center;
        border-right: 1px solid $neutral-1009;
        padding-right: 10px;
        width: 50%;
        color: $black;

        &:last-child {
            border-right: 0;
            padding-right: 0;
        }

        p:first-child{
            margin-bottom: 3px;
        }

        p:last-child {
            margin-bottom: 0;
            font-family: $Typo-Bold;
        }
    }
}

.MonthSelect {
    padding: 8px;
    width: 245px;

    .MonthBox {
        margin: 4px;
        border-radius: 4px;
        width: 68px;
        padding: 6px 10px;
        font-size: 0.8571rem;
        border: 1px solid #e8e8e8;
        cursor: pointer;
        float: left;
        text-align: center;
    }

    .monthBtnContainer {
        float: right;
        padding: 5px 5px 10px 0;

        .ms-Button{
            padding: 2px 8px;
            min-width: inherit;
        }
    }

    .monthSelected {
        background: #F2F8FD;
        border: 1px solid #0072ce;
    }

    .disabledMonth {
        opacity: 0.4;
        pointer-events: none;
    }
}
