
.multiselect-input{
    .css-yk16xz-control {
        min-height: 34px !important;
        height: 34px !important;
        border: 1px solid #B7DEFF !important;
        border-radius: 4px;
    }
    .css-1pahdxg-control {
        border: 1px solid #B7DEFF !important;
        box-shadow:none  !important;
        min-height: 34px !important;
        height: 34px !important;
    }
    .css-1okebmr-indicatorSeparator, .css-tlfecz-indicatorContainer {
        display:none;
    }
    .css-1rhbuit-multiValue {
        background-color: #f2f2f2;
        margin: 2px 2px 4px 2px;
    }
}