@import "../../../commonStyles.scss";

.autocomplete {
    width:100%;
}

.react-autosuggest__input{
	font-size: 11px;
	height: 34px  !important;
	border: 1px solid #B7DEFF !important;
	border-radius:4px;
}

.react-autosuggest__suggestions-list
{
  max-height: 100px;
  overflow-y: auto;
}