@import "../../../commonStyles.scss";

.search-dropdown, .css-yk16xz-control {
  height: 34px;
  border-color: $primary-1000!important;
  border-radius: 4px;
  font-size: $rts-1;

  .css-1okebmr-indicatorSeparator {
    display: none;
  }
  .css-1pahdxg-control {
    border-color: $primary-1000!important;
    box-shadow: none;
  }
}
