@import "../../../commonStyles.scss";

.ms-Dropdown,
.ms-Dropdown-title {
  height: 34px;
  border-color: $primary-1000!important;
  border-radius: 4px;
  font-size: $rts-1;

  .ms-Dropdown-caretDownWrapper {
    top: 2px;

    i {
      color: $neutral-1006 !important;
    }
  }
}
