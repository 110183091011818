@import '../../../commonStyles.scss';

.normalTable {
    .ka-table {
        .ka-thead {
            .ka-thead-cell {
                color: $black;
                background-color: $neutral-1108;
            }
        }
        .ka-tbody {
            tr {
                &:nth-child(even) {
                    background-color: $neutral-1109;
                }
            }
        }
    }

    .ka-table-wrapper {
        &::-webkit-scrollbar {
            width: 7px;
            height: 7px;
        }
    }
}

.globalSearch {
    position: relative;
    float: left;
    width: 280px;
    height: 34px;
    border-radius: 4px;
  
    &.ms-SearchBox {
      border-color: #C8E6FF;
      height: 34px;
      margin: 0;
      background: #0071CD05 0% 0% no-repeat padding-box;
      font-size: $rts-1;
  
      &::after {
        border-color: #C8E6FF;
      }
  
      .ms-SearchBox-icon {
        color: #C8E6FF;
      }
    }
}
.ka-thead-cell-content div span {
      float: left;
  }
.ka-thead-cell-content div::after {
  content: '';
  background-image: url(../../assets/images/sortIcon.png);
  width: 11px;
  height: 11px;
  background-repeat: no-repeat;
  background-size: contain;
  margin-left: 4px;
  float: left;
  margin-top: 2px;
}

.ka-thead-cell-content:has(.ka-icon-sort) div::after{
    display: none;
}