@import "../../../../commonStyles.scss";

.primary-btn {
  font-family: $Typo-Regular;
  font-size: $rts-1;
  border-radius: 2px;
  padding: 2px 8px;
  height: 35px;
  white-space: nowrap;
  background-color: $primary;
  border-color: $primary;
  &:focus,
  &:hover {
    color: $light;
    background-color: $secondary;
    border-color: $secondary;
  }
}
