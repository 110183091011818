@import "../../../commonStyles.scss";

.searchbox {
  position: relative;
  float: left;
  width: 245px;
  height: 33px;
  border-radius: 4px;
  margin-left: 9px;

  .ms-SearchBox {
    border-color: #C8E6FF;
    height: 34px;
    margin: 0;
    background: #0071CD05 0% 0% no-repeat padding-box;
    font-size: $rts-1;

    &::after {
      border-color: #C8E6FF;
    }

    .ms-SearchBox-icon {
      color: #C8E6FF;
    }
  }
}

.microphone-icon {
  position: absolute;
  right: 9px;
  top: 30px;
  color: $neutral-700;
}
