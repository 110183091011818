@import "../../../commonStyles.scss";

.file-upload-wrapper {
  border: 1px solid $neutral-400;
  height: 35px;
  position: relative;
  width: calc(100% - 91px);
  float: left;
  border-top-left-radius: 2px;
  border-bottom-left-radius: 2px;

  .choose-file {
    background-color: $neutral-800;
    color: $black;
    margin: 4px 3px;
    padding: 2px 5px;
    font-size: $rts-2;
    border-radius: 2px;
    float: left;
    border: 1px solid $neutral-900;
  }

  & + button {
    // float: left;
    height: 35px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: 10px;
  }

  .file-upload {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
  }

  .nofile-chosen {
    color: $neutral-1103;
  }

  .nofile-chosen,
  .file-name {
    font-size: $rts-1;
    margin-left: 10px;
    display: inline-block;
    margin-top: 10px;
    width: calc(100% - 100px);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

.upload-icon {
  margin-right: 5px;
}

.fileNamesBlock {
  margin-top: 10px;

  li {
    border: 1px solid $neutral-1114;
    padding: 5px;
    margin-bottom: 10px;
    list-style: none;
    background: $primary-1003;
    font-size: $rts-1;
    border-radius: 2px;

    &:last-child {
      margin-bottom: 0;
    }

    span {
      float: right;

      img {
        width: 10px;
        cursor: pointer;
      }
    }
  }
}