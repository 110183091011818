@import "../../../commonStyles.scss";

.required {
  color: $danger;
}

.label {
  font-family: $Typo-SemiBold, sans-serif;
  margin-bottom: 8px;
  font-size: $rts-1;
}
