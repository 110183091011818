@import "../../../commonStyles.scss";

.alert{
.ms-MessageBar-innerText{
    font-size: $rts-8 !important;
    color: #f89c63 !important;
}

.ms-MessageBar-text::before {
    content: '';
    width: 30px;
     height: 30px;
     background-image: url('../../assets/images/WorkInProgress.svg');
     background-repeat: no-repeat;
     background-size: contain;
     margin-right: 5px;
}
.ms-MessageBar-text{
    align-items: center;
    display: flex;
    justify-content: center;
    height: 26px;
}
&.ms-MessageBar {
    padding: 0px 10px!important;
    border-radius: 4px;
    border: 1px solid  #ffe7db !important;
    background: #fff7f4 !important;
   // color: #2d8ddb;
}

}