@import "../../../../commonStyles.scss";

.outline-btn {
  font-family: $Typo-Regular;
  font-size: $rts-1;
  border-radius: 2px;
  padding: 2px 8px;
  height: 35px;
  white-space: nowrap;
  border-color: $primary;
  color: $primary;
  &:focus,
  &:hover,
  &:active {
    background-color: $light;
    color: $primary;
    border-color: $primary;
  }
}
