@import "../../../../commonStyles.scss";

.product-category-entry-container {
    .category-modal-container {
        .sub-category {
            display: flex;
            justify-content: space-between;
            span {
                color: $primary;
                cursor: pointer;
            }
        }
    }
}