@import "../../../commonStyles.scss";

.datepicker {
  .ms-TextField-fieldGroup {
    height: 34px;
    border: 1px solid $primary-1000;
    line-height: 34px;
    padding: 0;
    &:hover {
      height: 34px;
      border: 1px solid $primary-1000;
    }
    .ms-TextField-field {
      font-size: 12px;
      padding: 0px 10px 0px 10px;
    }
    .ms-DatePicker-event--without-label {
      font-size: 13px;
      padding: 9px 8px 5px;
    }
  }
  .is-disabled{
    .ms-TextField-fieldGroup{
      &:hover{
        border:none;
      }
    }
  }
}
