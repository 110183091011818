@import "../../../commonStyles.scss";
 
.ms-Toggle.is-checked .ms-Toggle-background {
    background: $secondary;
    border: none;
    .ms-Toggle-thumb, .ms-Toggle-thumb:hover {
        background: $secondary;
    }
}

.ms-Toggle .ms-Toggle-background{
    background: #f0f0f0;
    border: none;
    &:hover{
        background: #f0f0f0;
        border: none;
    }
}

.ms-Toggle.is-checked .ms-Toggle-background:hover {
    border: none;
    background: $secondary;
}

.ms-Toggle.is-checked .ms-Toggle-thumb,.ms-Toggle-background:hover .ms-Toggle-thumb,.ms-Toggle .ms-Toggle-thumb,.is-checked .ms-Toggle-background:hover .ms-Toggle-thumb{
    background: $light !important;
}