@import "../../../commonStyles.scss";

.remove-all {
  .rdl-move-all {
    display: none;
  }
}

.react-dual-listbox {
  .rdl-move {
    border-color: $primary-100;

    span {
      color: $primary-100;
    }

    &:hover:not(:disabled) {
      border-color: $primary-100;
      background: $primary-100;

      span {
        color: $light;
      }
    }
  }

  .rdl-control {
    padding: 10px;
    font-size: $rts-1;
    overflow: auto;
    border: 1px solid $neutral-400;
  }

  select option {
    margin-bottom: 5px;
    padding: 5px 10px;
  }

  select option:hover,
  select option:focus,
  select option:active,
  select option:checked {
    background: linear-gradient($primary-100, $primary-100);
    background-color: $primary-100 !important; 
    color: $light;
    padding: 5px 10px !important;
  }

  .rdl-control-container:focus-visible {
    outline: none;
  }

  select:focus-visible {
    outline: none;
  }

  .fa-chevron-right,
  .fa-chevron-left {
    border-right: none;
    border-bottom: none;
    transform: none;
  }
}
