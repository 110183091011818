@import "../../../commonStyles.scss";

.badgebox {
  border-radius: 4px;
  padding: 2px 10px;
  text-align: center;
  font-size: 10px;
  display: inline-block;
}

.badge-success {
  background-color: $secondary-100;
  border: 1px solid $secondary;
  color: $secondary-300;
}

.badge-error {
  background-color: $danger-100;
  color: $danger;
  border: 1px solid $danger;
}

.badge-warning {
  background-color: $warning-100;
  color: $warning;
  border: 1px solid $warning;
}
