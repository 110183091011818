@import "../../../../../commonStyles.scss";

.reversePopup {
    .paymentDetailsContent {
        background: #F8FCFF;
        border: 1px solid $neutral-1107;
        border-radius: 5px;
        padding: 15px;
        .detail{
            font-size: $rts-1;
        }
        .heading{
            margin-bottom: 5px;
        }
        li {
            margin-right: 15px;
            float: left;
            min-width: 75px;
            list-style: none;
    
            &.paymentDetails_VendorName {
                width: 290px;
            }
        }
    
        .ms-TextField-fieldGroup{
            height: 27px !important;
    
            .ms-TextField-field {
                width: 85px;
            }
        }
    }

    .heading{
         .fa {
            font-size: $rts-2;
        }
    }

    .file-upload-wrapper {
        margin-bottom: 10px;
    }
}