@import "../../../../commonStyles.scss";

.paymentRenconcillation-container {
    .switchToggleContainer {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        .switchToggleLabel {
            font-size: 12px;
            color: black;
            padding: 0 10px 0px 20px;
            font-family: $Typo-Bold;
        }

        .switchToggle {
            padding-top: 4px;
        }
    }

    .searchBox {
        position: relative;

        .fa-search {
            cursor: pointer;
            position: absolute;
            top: 11px;
            right: 12px;
            color: $neutral-1007;
        }
    }
    th:first-child{
        .ka-thead-cell-content div{
            span{
                float: none;
                text-align: center;
            }
            &:after{                     
                display: none;            
            }
        }
    } 
    .modalPopupContainer{
        .modalBodyContainer{
            max-height: 100%;
            overflow-y: unset;
            padding: 15px 15px 0 15px;
        }
        .css-1nmdiq5-menu{
            // max-height: 170px;
            overflow-y: auto;
        }
    }
    .context-menu{
        margin-top: 3px;
    }

    .modalPopupMasterContainer {
        &.AssignPopup {
            .modalPopupContainer {
                width: 1100px;

                .modalBodyContainer {
                    width: 100%;
                }
            }
        }
    }
}

.AmtText {
    text-align: end;
}

