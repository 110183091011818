@import "../../../commonStyles.scss";

.navigation-wrapper {
  width: 100%;
  position: fixed;
  top: 70px;
  box-shadow: 0px 3px 6px #0000000B;
  z-index:9999;
  .header-navigation {
    display: flex;
    background: $primary;
    width:100%;
    .navmenu-list {
      list-style: none;
      width: 100%;
      margin: 0;
      background-color: $primary;
      display: inline-block;
      .search-block {
        float: right;
  
        .searchbox {
          float: right;
          width: 335px;
          height: 34px;
  
          .ms-SearchBox {
            border-color: $neutral-200;
            background-color: $neutral-1005;
            margin: 20px 0;
  
            &::after {
              border-color: $neutral-200;
            }
          }
        }
      }
  
      li {
        float: left;
        text-align: center;
        padding: 15px;
        cursor: pointer;
        &.active {
          background-color: $secondary;
          div {
            color: $light;
          }
        }
  
        &.department-menu {
          background-color: $primary-500;
          color: $light;
          float: right;
          max-width: 220px;
          font-size: $rts-3;
  
          .down-arrow {
            margin-left: 5px;
          }
        }
  
        div {
          text-decoration: none;
          color: $light;
          font-family: $Typo-Regular;
          font-size: 14px;
          // display: inline-block;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          span {
            margin-left: 5px;
          }
          img {
            height: 21px;
          }
          .menu-icon {
            position: relative;
            &::after {
              left: -10px;
              bottom: 7px;
              position: absolute;
              height: 15px;
              width: 15px;
            }  
          }
        }
      }
    }
  }
  

  .menu-bars {
    position: absolute;
    top: 20px;
    left: 15px;
    color: $danger;
    display: none;

    @include mobiledevices {
      display: block;
    }
  }

  .clear-both {
    clear: both;
  }
  .Myorder-mobile {
    display: none;
  }
}
@include mobiledevices {
    .header-navigation {
        .searchbox .ms-SearchBox {
            background: $light;
            padding-right: 10px;
        }

        .navmenu-list {
            position: fixed;
            bottom: 0;
            left: 0;
            z-index: 9999;
            display: flex !important;
            overflow-x: auto;
            justify-content: center;

            &.myorder-navigation {
                display: none !important;
            }

            li {
                flex: 0 0 17%;
            }
        }

        .search-block {
            position: absolute;
            top: -20px;
            margin-left: 15px;

            .searchbox {
                width: 237px;
                border-radius: 4px;
            }
        }
    }

    .navigation-wrapper {
        z-index: 99999;
    }

    .Myorder-mobile {
        display: block !important;
        background: #fff;
        width: 100%;
        height: 48px;
        position: fixed;
        margin-left: -15px;
        padding: 7px 10px 10px 10px;
        top: 72px;
        box-shadow: 0 0 10px #ccc;

        .attachment, .close {
            float: right;

            img {
                border: 1px solid $primary;
                padding: 10px;
                border-radius:2px;
            }
        }

        .attachment img {
            padding: 8px;
        }

        .labelHead {
            float: left;

            label {
                display: block;
                line-height: 14px;
            }
        }
    }
}

.search-block{
  .ms-SearchBox-iconContainer {
    position: absolute;
    left: 0;
    top:4px;
    transform: rotate(275deg);
  }
      input{
        text-indent: 25px;           
        &::placeholder { 
            font-size: $rts-1;
        }
      }                      
}

.nav-context {
  background-color: $primary-100;
  .ms-ContextualMenu-item {
    .ms-ContextualMenu-link {
      padding: 0px 10px;
      .ms-ContextualMenu-linkContent {
        border-bottom: 1px solid #FAFEFF2b;
        padding-right: 25px;
      }
      &:hover, &:focus {
        background-color: $primary-100;
        border: none;
      }
    }
    .is-disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
    &:nth-last-child(1) {
      .ms-ContextualMenu-link {
        .ms-ContextualMenu-linkContent {
          border-bottom: unset;
        }
      }
    }
    span {
      color: $light !important;
    }
  }
}