@import "../../../commonStyles.scss";

.form-checkbox {
  .ms-Checkbox-checkbox {
    border: 1px solid $primary;
  }

  .ms-Checkbox-checkbox:hover {
    border: 1px solid $primary;
  }

  &.is-disabled .ms-Checkbox-checkbox {
    background-color: rgb(243, 242, 241);
    border: 1px solid rgb(243, 242, 241);
  }

  .ms-Checkbox-text{
    line-height: 11px;
  }
}
.ms-Checkbox.is-checked label .ms-Checkbox-checkbox {
  background-color: $primary;
  border: 1px solid $primary;
}

.smallCheckbox {
  .ms-Checkbox-checkbox {
    width: 15px;
    height: 15px;
    margin-top: -1px;
  }

  .ms-Checkbox-checkmark {
    font-size: 12px;
  }
}
