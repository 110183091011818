@import "../../../commonStyles.scss";

.form-input {
  .ms-TextField-fieldGroup {
    height: 34px  !important;
    border: 1px solid $primary-1000 !important;
    border-radius:4px;

    input[readonly] {
      background-color: #eee;
      border-color:#eee;
    }

    &::after {
      border: 1px solid #B7DEFF !important;
    }

    .ms-TextField-field {
      font-size:$rts-1;
      color:$black; 
      &::placeholder {
        opacity:0.5;
      }
    }
  }
}
