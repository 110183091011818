@import "../../../commonStyles.scss";


.react-checkbox-tree {
    li {
        position:relative;
        padding-bottom:10px;
    }
    li::before, li::after {
        content: "";
        position: absolute;
        left: -7px;
    }
    li::before {
        width: 35px;
        height: 0px;
        top: 13px;
        border-top: 1px solid #D8D8D8 !important;

    }
    li:first-child::before {
        top:24px;
    }
    li::after {
        border-left: 1px solid #D8D8D8;
        height: 100%;
        width: 0px;
        top: 0;
    }

}
.react-checkbox-tree ol li ol li:before {
    width: 19px,
  }
  .react-checkbox-tree ol li ol li ol li:before {
    width: 19px,
  }
  .react-checkbox-tree ol > li:last-child::after {
    height:13px
  }
  .react-checkbox-tree > ol > li:before {
    border-top: none !important
  }
  .react-checkbox-tree > ol > li:after {
      height:0 !important
  }
.rct-icon-expand-close {
    content:url('../../assets/images/plus.png');
    opacity: 1 !important;
    color: #000;
    width: 15px !important;
    height: 15px;
    border: 1px solid #ccc;
    border: 1px solid #D8D8D8;
    border-radius: 2px;
}
.rct-icon-expand-open{
    content:url('../../assets/images/minus.png');
    opacity: 1 !important;
    color: #000;
    width: 15px !important;
    height: 15px;
    border: 1px solid #ccc;
    border: 1px solid #D8D8D8;
    border-radius: 2px;
    background-color: $light;
}
.rct-text{
    background: #FDFDFD 0% 0% no-repeat padding-box;
    border: 1px solid #E4E4E4;
    border-radius: 4px;
    width: auto;
    padding: 7px 5px 2px 5px;
    line-height: 20px;
}
.rct-collapse *, .rct-checkbox *, .rct-node-icon * {
    display: inline-block;
    margin: 0;
    width: auto !important;
}

.react-checkbox-tree label:active, .react-checkbox-tree label:focus {
    background: inherit !important;
}
.react-checkbox-tree label:hover {
    background: inherit !important;
}
.rct-node-parent {
    li {
        padding-left:10px;
        padding-bottom:10px;

        &:first-child {
            padding-top:10px;

            .rct-icon-check::before {
                top: 26px;
            }
        }

        .rct-collapse {
            display:none;
        }
    }
    &.rct-node-expanded {
        .rct-text {
            background: #8BC400;
            border: 1px solid #8BC400;
            color:$light;
            .rct-icon-check {
                display: none;
            }
            .rct-title {
                font-size: 12px;
                display: block;
                margin-top: -23px;
                font-family: $Typo-Bold;
                margin-left: 0;
                padding: 1px 5px;
            }
        }
        .rct-node-leaf {
            .rct-text {
                background: #FDFDFD 0% 0% no-repeat padding-box;
                border: 1px solid #E4E4E4;
                color:$dark-100;
                .rct-icon-check {
                    display: inline-block;
                    border: 1px solid #0072CE;
                    width: 12px !important;
                    height: 12px !important;
                    border-radius: 2px;
                    padding:6px;
                    background-color: #0072CE;
                }
                .rct-icon-uncheck {
                    display: inline-block;
                    border: 1px solid #D8D8D8;
                    width: 12px !important;
                    height: 12px !important;
                    border-radius: 2px;
                    padding:6px;
                }
                .rct-title {
                    font-size: 11px;
                    display: block;
                    margin-top: -23px;
                    margin-left: 21px;
                }
                label {
                    span[aria-checked='true'] {
                        &.rct-text {                            
                                background: red;
                        }
                    }

                }
            }
        }
    }
    &.rct-node-collapsed {
        .rct-text {
            .rct-icon-check {
                display: none;
            }
            .rct-title {
                font-size: 12px;
                display: block;
                margin-top: -21px;
                font-family: $Typo-Bold;
                margin-left: 0;
                padding: 1px 5px;
            }
        }
    }
}
.rct-icons-fa4 .rct-icon-check::before {
    position: absolute;
    left: 18px;
    top: 16px;
    height: 8%;
    width: 1px;
    background-color: #fff;
    content: "" !important;
    transform: translateX(9px) rotate(-45deg);
    transform-origin: left bottom;
}

.rct-icons-fa4 .rct-icon-check::after {
    position: absolute;
    left: 17px;
    bottom: 20px;
    height: 1px;
    width: 9px;
    background-color: #fff;
    content: "" !important;
    transform: translateX(10px) rotate(-45deg);
    transform-origin: left bottom;
}
.rct-icons-fa4 .rct-icon-half-check::before {
    opacity: 0.5;
    content: "" !important;
}
.rct-icons-fa4 .rct-icon-uncheck::before {
    content: "" !important;
}