.mylocationpagging {
    .paginationItem {
        text-align: center;
        font-size: 13px;
        display: flex;
        justify-content: center;
        padding: 5px 0;

        &:last-child {
            text-align: right;
            font-size: 12px;
            margin-top: 0px;
            margin-left: auto;
            margin-right: 5px;
            width: 180px;
            justify-content: end;
        }

        .forwardIcon {
            font-size: 9px;
            margin: 5.5px 5px 0 5px !important;
            display: block;
        }

        .fa {
            margin: 0 5px;
            // color: #707070;
            color: #242424;
        }

        .pagerIndexCount {
            height: 15px;
            width: 15px;
            background-color: #B7DEFF;
            max-height: 15px;
            padding: 2px 2px 2px 1px;
            font-size: 11px;
            text-align: center;
            margin: 2px;
            line-height: 15px;
            border-radius: 2px;
        }

        a {
            max-height: 8px;

            &.disableLink {
                .playBtn {
                    cursor: no-drop;
                    opacity: 0.5;
                }
            }
        }
    }
}

.mylocationpagging.paginationStyle {
    border: 1px solid #D9D9D9;
    padding: 6px 5px 2px 5px;
    border-radius: 4px;
    margin-top: 5px;
    background: #fff;
    display: flex;

    span {
        font-size: 12px;
        margin: 2px 0 0 5px;
    }

    .paginationDropdown {
        width: 210px;
        font-size: 12px;
        white-space: nowrap;
        text-align: center;
        display: flex;
        padding: 4px 5px 5px 5px;

        select {
            border-color: #eee;
            padding: 2px;
            border-radius: 4px;
        }
    }
}