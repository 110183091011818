@import '../../../../../commonStyles.scss';

.backButton {
    border-radius: 2px;
    width: 34px;
    height: 34px;
    line-height: 34px;
    background-color: $primary;
    text-align: center;
    float: left;
    margin-right: 15px;
    cursor: pointer;
}

.leftsideButton {
    font-size: $rts-1;
    & > div {
        float: left;
        margin-right: 15px;

        &:last-child {
            margin-right: 0;
        }
    }
}

.viewBulkUpload {
    .globalSearch {
        top: 18px;
    }

    .searchBlock {
        width: 405px;
    }

    .autoCorrect {
        margin-top: 10px;
    }

    .ka-table .ka-thead-cell:first-child .ka-thead-cell-content {
        justify-content: center;
    }
}
