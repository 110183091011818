.alertMessage {
    position: relative;

    .ms-MessageBar-actions {
        position: absolute;
        right: 35px;
        top: 6px;
    }
    
    .ms-MessageBar-actions {
        .ms-Button {
             min-width: inherit;
             padding: 5px;
             min-height: 30px;
             margin: 0 0 0 8px;
             height: 30px;
         }
    } 
}


