@import "../../../commonStyles.scss";

.accordion {
  .accordion-button {
    font-family: $Typo-Bold;
    font-size: $rts-1;
    padding: 10px;

    &::after {
      background-position-y: 2px;
      background-size: 1rem;
    }

    &:not(.collapsed) {
      background-color: $primary-200;
      box-shadow: none;
      color: $light;
      background-position-y: 0;

      &::after {
        background-image: url("../../assets/images/down-white.svg");
      }
      
    }

    &:focus {
      box-shadow: none;
    }
  }

  .accordion-item {
    margin-bottom: 15px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .accordion-item:not(:first-of-type) {
    border-top: 1px solid #00000020;
  }
}

.accordionPanel {
  border: 1px solid $neutral-400;
  font-size: $rts-3;
  padding-bottom:0;
}

.accodion-container  {

  .accordionHeading {
    border: 1px solid #C4C6C880 !important;
    border-radius: 2px;

    .accordion__button {
      background-color: $light !important;
      padding: 10px !important;
      color: $black  !important;
	    height: 58px;
      
      &[aria-expanded='true'] {
        background: #F8FCFF 0% 0% no-repeat padding-box !important;
		    height: 58px;
	  }

      &[aria-expanded='true']::before, &[aria-selected='true']::before {
        content:url('../../assets/images/Accordion\ Up.svg');
      }

      &::before {
        content: url('../../assets/images/Accordion\ Down.svg');
        float:right;
        transform: none;
        padding-right:15px;
        color:transparent;
        margin-top:5px;
      }
    }
  }

  .accordionPanel {
    border-top: none !important;
  }

  div[data-accordion-component="AccordionItem"] {
    margin-bottom:15px;
    border-top:none;
  }
}
