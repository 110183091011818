@import "../../../commonStyles.scss";

select.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: $rts-3;
  line-height: 1.5;
  background-color: $light;
  background-clip: padding-box;
  border: 1px solid $neutral-500;
  height: 35px;
  color: $neutral-1105;
  border-radius: 2px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: $Typo-Regular;

  &:focus {
    outline: none;
    box-shadow: none;
    border-color: $neutral-400;
  }
}

.custom-select::after {
  background-color: red;
}
