@import "../../../commonStyles.scss";

.toggle {
    --width: 165px;
    --height: 35px;

    position: relative;
    display: inline-block;
    min-width:135px;
    height:35px;
    border-radius: 25px;
    cursor: pointer;
  }

  .toggle input {
    display: none;
  }

  .active .toggle .slider {
    background-color: $secondary;
    color:$light;
  }
  .completed .toggle .slider {
    background-color: $light;
    border-color:$secondary;
    color:$black;
  }

  .toggle .slider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 25px;
    background-color: $light;
    border: 1px solid #C4C6C8;
    transition: all 0.4s ease-in-out;
  }

  .active .toggle .slider::before {
    background-color: $light;
    color:$black;
  }
 .completed .toggle .slider::before {
        color:$black;
        transform: translateX(calc(var(--width) - var(--height)));
        background-image: url('../../assets/images/Tick\ icon.svg');
        background-color: transparent;
        content: '';
        background-repeat: no-repeat;
        background-size: 30px 30px;
  }
  .toggle .slider::before {
    content: attr(data-on);
    position: absolute;
    top: 2px;
    left: 2px;
    width: 28px;
    height: 28px;
    line-height: 28px;
    text-align: center;
    border-radius: 25px;
    background-color: #B3B3B3;
    color:$light;
    transition: all 0.4s ease-in-out;
  }

  .toggle .labels {
    position: absolute;
    top: 8px;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-family: sans-serif;
    transition: all 0.4s ease-in-out;
  }

  .active .toggle .labels::after {
    color:$light;
 }
 .completed .toggle .labels::after {
    color:$light;
    opacity:0;
 }
  .toggle .labels::after {
    content: attr(data-off);
    position: absolute;
    right: 25px;
    top:2px;
    color: $black;
    opacity: 1;
    font-size:$rts-1;
    transition: all 0.4s ease-in-out;
  }

  .completed .toggle .labels::before {
  opacity:1;
  color:$black;
}
  .toggle .labels::before {
    content: attr(data-on);
    position: absolute;
    left: 25px;
    top:2px;
    color: #ffffff;
    opacity: 0;
    transition: all 0.4s ease-in-out;
  }
  .custom-stepper-list {
      display:flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: space-between;
      width: 100%;
      margin: 0;
      padding: 0;
      position: relative;

      .stepper-list {
        .toggle {
          min-width:165px;
        }
      }
      .btn-block {
        float:right;
        width:100%;
      }
  }
  .custom-stepper-list::after{
    content: '';
    border-bottom: 1px solid lightgray;
    position: absolute;
    transform: translateY(-50%);
    top: 18px;
	width: 10%;
    left: 48%;
  }
  .custom-stepper-list.enable::after{
    content: '';
    border-bottom: 1px solid $secondary;
  }
  .stepper-list:first-child {
    padding-left: 0;
}

.stepper-list:last-child {
    padding-right: 0;
}
.stepper-list {
    z-index:999
}