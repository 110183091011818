@import '../../../../../commonStyles.scss';

.AddEditPopup {
    font-size: $rts-1;

    .boldText {
        font-weight: $Typo-SemiBold;
    }

    .addBtn {
        border: 1px solid $primary-300;
        width: 35px;
        height: 35px;
        border-radius: 2px;
        line-height: 35px;
        text-align: center;
        cursor: pointer;
    }

    .deleteBtn {
        border: 1px solid $neutral-400;
        width: 35px;
        height: 35px;
        border-radius: 2px;
        line-height: 35px;
        text-align: center;
        background-color: $neutral-600;
        cursor: pointer;
    }

    .list {
        border: 1px solid $neutral-400;
        padding: 8px 10px;
        border-radius: 2px;
    }

    .listBox {
        max-height: 315px;
        overflow-x: hidden;
        padding-right: 12px;
        margin-right: -15px;
    }

    .editIcon {
        cursor: pointer;
    }
}