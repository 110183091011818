@import '../../../commonStyles.scss';

.breadcrumb {
  margin: 0 0 10px 0;
  .ms-Breadcrumb-itemLink {
      color: $primary;
      font-size: $rts-3;
      padding: 5px 10px;
      border-radius: 5px;

      &:last-child {
          cursor: pointer;
          text-decoration: none;
          color: $neutral !important;
          font-weight: 400;

          &:hover {
              color:$primary !important
          }
      }

      &[active ='true'] {
        background-color: $primary;
        color: $light !important;
        text-decoration: none;

        &:hover {
          color:$light;
        }        
      }
  }
  .ms-Breadcrumb-chevron {
    font-size: 9px;
    padding-top: 3px;
    color: $primary;
    font-weight: bold;
  }

  .ms-TooltipHost {
    font-size: 12px;
  }
  .ms-Breadcrumb-item {
    line-height: 30px;
  }
}
@include mobiledevices {
  .breadcrumb {
    margin:0
  }
  .ms-Breadcrumb-chevron {
    margin: 5px 0px 0 5px;
  }
}