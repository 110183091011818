@import "../../../commonStyles.scss";

.pivot {
  .ms-Pivot-link {
    background-color: $light;
    color: $dark-100;
    border: 1px solid $neutral-400;
    margin-right: 0;
    font-family: $Typo-Regular;
    font-size: $rts-1;
    min-width: 70px;
    font-weight: normal;
    padding: 0 15px;

    &:first-child {
      border-top-left-radius: 4px;
    }

    &:last-child {
      border-top-right-radius: 4px;
    }
  }

  .ms-Pivot-link.is-selected {
    background-color: $primary-100;
    color: $light;
    border: 1px solid $neutral-400;

    &::before {
      display: none;
    }
  }
}
