@import '../../../commonStyles.scss';

.tooltipContainer {
    width:  340px;
    border-radius: 10px;
    z-index: 99;
    .tooltipHeader {
        background-color: $secondary-400;
        color: $light;
    }

    &::before {
        content: '';
        display: inline-block;
        position: absolute;
        left: 14px;
        bottom: -8px;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #efefef;
        border-left: 8px solid transparent;
        transform: rotate(180deg);
    }

    &::after {
        content: '';
        display: inline-block;
        position: absolute;
        left: 15px;
        bottom: -6px;
        border-right: 7px solid transparent;
        border-bottom:7px solid #fff;
        border-left: 7px solid transparent;
        transform: rotate(180deg);
    }
}
