@import "../../../commonStyles.scss";

.quantity-spinner {
  display: flex;

  input {
    width: 30px;
    border-radius: 0;
    border-color: $primary-1000;
    border-left: 0;
    border-right: 0;
    text-align: center;
    height: 32px;
    border: 1px solid $primary-1000;

    &:focus {
      outline: none;
    }
    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  button {
    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }

  .minus-icon {
    max-width: 14px;
  }

  .plus-icon {
    max-width: 20px;
  }

  .control {
    background-color: $primary-901;
    width: 28px;
    text-align: center;
    color: $neutral-1008;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 32px;
    padding: 0 12px;

    &:first-child {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
      border: 1px solid $primary-1000;
    }

    &:last-child {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
      border: 1px solid $primary-1000;
    }
  }
}
