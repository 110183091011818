$primary: #0071cd;
$primary-100: #0072ce;
$primary-200: #4c8af6;
$primary-300: #0071ce;
$primary-400: #015eaa;
$primary-500: #0060aa;
$primary-600: #0073cd;
$primary-700: #f2f6f7;
$primary-800: #f4f8fc;
$primary-900: #f1f6f7;
$primary-1000: #B7DEFF;
$primary-901: #0071CD0F;
$primary-1001: #F9FCF6;
$primary-1002: #F8FCFF;
$primary-1003: #fafafa;
$secondary:  #8bc400;
$secondary-100: #8bc40033;
$secondary-200:#78c624;
$secondary-300: #73A200;
$secondary-400: #8EC750;
$secondary-500:#EAF4D1;
$secondary-600: #457B09;
$secondary-700: #00B050;
$secondary-800: #009A17;
$neutral: #b3b3b3;
$neutral-100: #f2f2f2;
$neutral-200: #e4e4e4;
$neutral-300: #0000000b;
$neutral-400: #e8e8e8;
$neutral-500: #e5e2e2;
$neutral-600: #f9f9f9;
$neutral-700: #5a5a5a;
$neutral-800: #f6f6f6;
$neutral-900: #ddd;
$neutral-1000: #57585a;
$neutral-1001: #8d8d8d;
$neutral-1002: #00000012;
$neutral-1003: #ededed;
$neutral-1004: #d8d8d8;
$neutral-1005: #fdfdfd;
$neutral-1006: #ced4da;
$neutral-1007: #707070;
$neutral-1008: #787878;
$neutral-1009: #ccc;
$neutral-1100: #e9ecef;
$neutral-1101: #262626;
$neutral-1102:#f7f7f7;
$neutral-1103: #57585a;
$neutral-1104: #00000026;
$neutral-1105: #323130;
$neutral-1106: rgba(0, 0, 0, 0.4);
$neutral-1107: #C4C6C880;
$neutral-1108: #B3B3B30D;
$neutral-1109: #FBFBFB;
$neutral-1110: #EBEBEB;
$neutral-1111: #f3f2f1;
$neutral-1112: #B2B2B2;
$neutral-1113: #757171;
$neutral-1114: #dfe2e4;
$Info: #31708f;
$Info-100: #eef7fb;
$Info-200:#90dbee;
$Info-300: #a6e1ec;
$Info-400:#22839d;
$Info-600:#4c8af6;
$Info-700:#4051b5;
$info-800:#EFF8FF;
$info-900: #A3D5FF;
$info-1000: #0071CD;
$Info-800: #EAF3FC;
$info-900: #02B1F0;
$info-1000: #0000FF;
$danger: #f00;
$danger-100: #ff00001A;
$warning: #fc9404;
$warning-100: #fc940433;
$warning-101: #FF0000;
$warning-102: #ED7D31;
$yellow: #ffc107;
$yellow-100: #E6B015;
$yellow-200: #FFC000;
$black: #000;
$dark: #222;
$dark-100:#333;
$dark-200: #555;
$dark-300: #212529;
$light: #fff;
$pending: #FAF1DC;
$pending-100: #DDB66F;
$totalOrder: #0071CD4D;



$Typo-SemiBold: ArialMedium, sans-serif;
$Typo-Bold: ArialBold, sans-serif;
$Typo-Regular: Arial, sans-serif;
$rts-1: 0.8571rem; //12px
$rts-2: 0.9285rem; 
$rts-3: 1rem;
$rts-4: 1.0714rem;
$rts-5: 1.1428rem;
$rts-6: 1.2142rem;
$rts-7: 1.2857rem;
$rts-8: 1.3571rem;
$heading-1: 1.4285rem; //20
$heading-2: 1.5714rem; //22
$heading-3: 1.7142rem;
$heading-4: 1.8571rem;
$heading-5: 2rem;
$heading-6: 2.1428rem;


%commonstyles {
  p {
    margin: 0;
  }
}

@mixin largedesktop1900 {
  @media only screen and (min-width: 1900px) {
    @content;
  }
}

@mixin desktop1536 {
  @media only screen and (max-width: 1536px) {
    @content;
  }
}

@mixin desktop1440 {
  @media only screen and (max-width: 1440px) {
    @content;
  }
}

@mixin desktop1280 {
  @media only screen and (max-width: 1280px) {
    @content;
  }
}

@mixin desktop1200 {
  @media only screen and (max-width: 1200px) {
    @content;
  }
}

@mixin largedesktop1500 {
  @media only screen and (min-width: 1500px) {
    @content;
  }
}

@mixin desktop1100 {
  @media only screen and (max-width: 1100px) {
    @content;
  }
}

@mixin smalldesktop {
  @media only screen and (max-width: 1024px) {
    @content;
  }
}

@mixin device1000 {
  @media only screen and (max-width: 1000px) {
    @content;
  }
}

@mixin tablet {
  @media only screen and (max-width: 768px) {
    @content;
  }
}

@mixin mobiledevices {
  @media only screen and (max-width: 600px) {
    @content;
  }
}
