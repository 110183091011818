@import '../../../commonStyles.scss';
.small {
    .ms-ChoiceField-field field-186 {

        &::before {
            width: 16px;
            height: 16px;
        }

        &:hover::after {
            content: "";
            transition-property: background-color;
            left: 3px;
            top: 7px;
            width: 10px;
            height: 10px;
            background-color: rgb(96, 94, 92);
        }
    }
}
.ms-ChoiceField-field.is-checked {
    &::before {
        border-color: $primary;
    }

    &::after {
        border-color: $primary;
        background-color: $primary;
    }
}