@import "../../../commonStyles.scss";

.carousel {
    &:hover {
        .slide {
            .legend {
                opacity:unset;
            }
        }
    }
    li.slide {
        height:250px;
    
        img {
            height:250px;
            padding-left: 70px;
            @include mobiledevices {
                padding-left:45px;
            }
        }
    
        .legend {
            bottom: 0px;
            left: 45%;
            margin-left: -45%;
            width: 50%;
            border-radius:0;
            background-color: rgba(0,113,205,0.6);
            text-align: left;
            padding: 30px 14px;
            max-height: 155px;
            min-height:155px;
            overflow-y: auto;
            opacity: unset;
        }
    }
}
.carousel .control-dots {
    left: 52%;
    text-align: left;
}
.container{
    position: absolute;
    bottom: 0;
    left: 23%;
    width: 50%;
  }
    
  .text{
    display: inline;
    width: 100%;
    color: #000;
    background: #fff;
  }
    
  .read-or-hide{
    color: rgb(192,192,192);
    cursor: pointer;
    clear: both;
    width: 100%;
    display: block;
  }
  .Viewmore,.Showless{
    float:right;
    font-family: $Typo-Bold;
    color:$light;
  }

  .LegendHeading{
    transition: all .5s ease-in-out;
    position: absolute;
    bottom: 134px;
    left: 46%;
    margin-left: -45%;
    width: 20%;
    border-radius: 0px;
    background: #58585a;
    color: #fff;
    padding: 10px;
    font-size: 12px;
    text-align: center;
    opacity: 0.9;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    z-index: 99;
 }

 .dot.selected {
    background-color: $primary !important;
    // box-shadow: 1px 1px 2px rgb(0,113,205 / 90%) !important;
 }
 .segmentHeading {
    font-size:$rts-3;
    color:$primary;
    font-family:$Typo-Bold;
    text-align: left;
    margin-bottom:5px;
 }
