@import "../../../commonStyles.scss";

.ms-DocumentCard {
  min-width: 100%;
  max-width: 100%;
  border: none;
  box-shadow: 0px 0px 8px #0000000B;
  border-radius: 10px;
}

.ms-DocumentCard .ms-DocumentCardDetails {
  padding: 15px 20px;
  box-shadow: 0px 0px 8px #0000000b;
  border-radius: 10px;
}
