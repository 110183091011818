@import "./commonStyles.scss";

body {
  @extend %commonstyles;
  color:#000;
  overflow:hidden ;
  height: auto!important;
}

.custom-breadcrumb > div > div > div {
  position: absolute !important;
  visibility: visible !important;
  top: -10px;
}

ul {
  margin: 0;
  padding: 0;
}

.context-menu {
  width: 22px;
  border-radius: 50%;
  background-color: $primary-100;
  height: 22px;
  line-height: 22px;
  text-align: center;
  display: block;
  margin: 0 auto;
  color: $light;
}
.context-menu span {
  color:$light;
}
.ms-DocumentCard .ms-DocumentCardDetails {
  padding: 15px;
}
.clearfix {
  clear: both;

  &::after {
    clear: both;
    display: block;
    content: '';
  }
}
.addEdit {
  font-size: $rts-1;
}
.float-left {
  float: left;
}
.float-right {
  float: right;
}
::-webkit-scrollbar {
  width: 4px;
  height: 8px;
  background-color: #cfc2c2;
}
.ka-table-wrapper, .card-block, .modalBodyContainer, .create-new-order-container {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: #cfc2c2;
  }
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: #ccc;
}

::-webkit-scrollbar-thumb {
  background-color: #aaa;
}

::-webkit-scrollbar-thumb:hover {
  background: #aaa;
}
@include mobiledevices {
  .order-1 {
    order:1;
  }
  .order-2 {
    order:2;
  }
}

.is-disabled  {
  input, label {
    opacity: 0.7;
    cursor: not-allowed;
  }
}