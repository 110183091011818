@import "../../../commonStyles.scss";

.dialog-msg {
  text-align: center;
}
.user-profile-container {
  .hide {
    display:none;
  }
  .ms-Dialog-subText {
      margin-bottom:0;
  }
  .cancelUser {
    display: none;
  }
  .errorMsg{
    font-size: $rts-1;
    color:$danger;
    margin-bottom:5px;
    margin-top:-10px;
  }
  .profile {
    font-family: $Typo-Bold;
    font-size: $rts-3;
    color:$primary;
    margin-bottom: 15px;
  }

  .driver-Info {
    font-family: $Typo-Bold;
    font-size: $rts-1;
    color:$black;
  }

  .privacy-info {
    font-size: $rts-1;
    color:$black;
  }

  .fileupload-info {
    border: 1px solid #B7DEFF;
    border-radius: 4px;
    background: #0071CD05 0% 0% no-repeat padding-box;
    font-size: 10px;
    padding: 10px;
  }

  .profile-requirement {
    background: #0071CD05 0% 0% no-repeat padding-box;
    font-size: 10px;
    padding: 10px;
    border-radius: 4px;
  }
  .validation-errorMsg {
    color:$danger;
    font-size:10px;
  }

  .hidden-field{
    display: none;
  }

  .fileupload-userprofile {
    .file-upload-wrapper {
      border: 1px dashed $primary;
      position: relative;
      width: 59px;
      height: 59px;
      float: left;
      border-radius: 50%;
      background: #0072CE0D 0% 0% no-repeat padding-box;

      .file-upload {
        width: 59px;
        height: 59px;
        border-radius: 50%;
      }

      .choose-file {
        background-color: transparent;
        color: $primary;
        font-size: 13px;
        border-radius: 2px;
        float: none;
        text-align: center;
        border: none;
        width: 50px;
        line-height: 40px;
      }
      .img-active {
        height: 57px;
        width: 57px;
        border-radius: 50px;
        position: absolute;
        left: 0px;
        top: 0px;
      }
    }
  }

  .userprofile-tab {
    div[role="tabpanel"] {
      border:none;
      padding:15px 0;
    }
    .ms-Pivot-link {
      font-size: $rts-1;
      font-family: $Typo-Bold;
      min-width:142px;
      height:34px;
      line-height:34px;
    }
    .ms-Pivot-linkContent, .ms-Pivot-text{
      height:34px;
    }
  }
}
