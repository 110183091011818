@import '../../../commonStyles.scss';

.unauthorized-container {
    border:1px solid #dee2ef;
    border-radius:10px; 
    background-color: $light;
    display: flex;
    flex-direction: column;
    min-height: 78vh;
    justify-content: center;
    align-items: center;
    color:$dark-100;

    &.fallback {
        height:100vh;
        .center-block .error-image {
            height: 100%;
            width: 100%;

            @include mobiledevices {
                width:80%;
            }
        }
    }

    @include tablet {
        min-height:85vh;
    }
    @include mobiledevices {
        min-height: 75vh;
    }

    .center-block {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        margin-bottom:30px;

        .content-section {
            .error-text {
                font-size:50px;
                line-height:50px;    
                @include mobiledevices {
                    font-size:26px;
                    line-height:40px;   
                }
            }
            .error-number {
                font-size:115px;
                line-height:115px;    
                font-family: $Typo-Bold;  
                @include mobiledevices {
                    font-size:62px;
                    line-height:44px;   
                }       
            }      
        }
        .error-image {
            height:30%;
            width:60%;
        }
    }
   
    .error-name {
        font-size:36px;
        font-family: $Typo-Bold;
        margin-bottom:30px;
        @include mobiledevices {
            font-size: $heading-2;
        }
    }
    .error-description {
        font-size:$heading-1;
        @include mobiledevices {
            font-size: $rts-3;
        }
    }
}

.ErrorScreens {
    .alert-msg{
        &.ms-MessageBar{
            &.ms-MessageBar--error {
                background: #F8D7DA;
                border: 1px solid #F5C4C9;
                color: #8E2537;
            }
        }
    }
    .AlertMessageStyle {
        padding: 5px 10px 20px;
    
        .AlertMessageHeading {
            font-weight: normal;
            margin-bottom: 6px;
            font-size: 28px;
    
            .fa {
                font-size: 24px;
                margin-right: 7px;
                color: #8E2537;
            }
        }
    }
}

