@import "../../../commonStyles.scss";

.selfTrackboard {
	// max-width: 100%;
	overflow: hidden;
	overflow-x: auto;
	background-color: #fff;
	width: 100%;
	border-radius: 10px 10px 0 0;
	min-height: 265px;

	.selfTrackboardMain {
		display: table;
	}

	.FixedlftColumn {
		position: sticky;
		left: 0;
		width: 275px;
		z-index: 99;
		top: 0;

		&.SearchBoxArea {
			background-color: #F2F8FC;
		}

		&.VendorsColumn {
			background-color: $primary;
		}

		&.selfTrackRowName {
			background-color: #fff;
		}
	}
}


.selfTrackboard div {
	font-size: 12px;
}

.selfTrackHead {
	background-color: #0071CD;
	color: #fff;
	min-height: 36px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-family: $Typo-Bold, sans-serif;
}

.vendorsColumn {
	min-width: 250px;
}

.bglightBlue {
	background-color: #F2F8FC;
}


.vendorsColumn div.searchbox, .vendorsColumn div.ms-SearchBox-iconContainer{
	min-height: 30px;
	border: none;
}

.colinnerdiv div.bglightBlue:first-child {
	font-family: $Typo-Bold, sans-serif;
}


div.selfTrackboardVendor{
	display: flex;
	flex-direction: row;
	padding: 0;
	min-width: 250px;
	background-color: #fff;

	.selfTrackHead{
		//min-width: 375px;
		border-right: 1px solid #ccc;

		&.MonthFullWidth {
			min-width: 375px;
		}

		&.MonthHalfWidth {
			min-width: 250px;
		}

		&.MonthQuarterWidth {
			min-width: 125px;
		}

		&.MonthNoWidth {
			min-width: 0px;
		}

		&.VendorsColumn{
			min-width: 250px;
			justify-content: left;
			padding-left: 10px;
			display: flex;
			justify-content: space-between;

			img {
				cursor: pointer;
				margin-left: 3px;
			}

			.notReceivedCheckbox {
				margin-right: 10px;
				margin-top: 2px;

				.ms-Checkbox {
					margin-bottom: 0;

					.ms-Checkbox-checkbox {
						width: 13px;
						height: 13px;
						border-color: #fff;
					}

					.ms-Checkbox-text {
						color: #fff;
						font-size: $rts-1; 
						font-family: $Typo-Bold, sans-serif;
					}
				}
			}
		}
	}

	.selfTrackboardVendorInner {	
		padding: 0;
		// min-width: 375px;

		.selfTracInnerSecond {
			display: flex;
			flex-direction: row;

			div {
				width: 125px;
				padding: 5px;
				border-bottom: 1px solid #ccc;
				text-align: center;
				border-right: 1px solid #ccc;
			}

			&:first-child {
				div {
					font-family: $Typo-Bold, sans-serif;
				}
			}

			.selfTracInnerSecondIcon {
				position: relative;
				bottom: 2px;
				left: 3px;
				width: 13px;
			}

			img {
				cursor: pointer;
			}
		}

		&.SearchBoxArea {
			padding: 5px;
			min-width: 250px;
			border: 1px solid #ccc;
			display: flex;
			align-items: center;

			.ms-SearchBox{
				background-color: #fff;
			}
		}
	}

	&.selfTrackboardVendorRow{
		.selfTrackRowName{
			min-width: 250px;
			padding: 0 7px;
			display: flex;
			color: #000;
			font-family: $Typo-SemiBold, sans-serif;
			font-size: 13px;
			flex-direction: row;
			justify-content: space-between;
			align-items: center;
			border-right: 1px solid #ccc;
			border-left: 1px solid #ccc;
			border-bottom: 1px solid #ccc;
			border-top: 0;
			
			.selfTrackRowCategory{
				font-size: 10px;
				color: #000;
				opacity: 0.7;
			}

			&.notReceivedBorder {
				border-left: 4px solid #DB2727;
			}
		}
		.selfTrackboardRowData{
			display: flex;
			flex-direction: row;
			//min-width: 375px;

			&.MonthFullWidth {
				min-width: 375px;
			}
	
			&.MonthHalfWidth {
				min-width: 250px;
			}
	
			&.MonthQuarterWidth {
				min-width: 125px;
			}		

			&.MonthNoWidth {
				min-width: 0px;
			}
			
			div {
				//width: 33.33%;
				padding: 5px;
				background-color: #fff;
				border-bottom: 1px solid #ccc;
				border-right: 1px solid #ccc;
				text-align: center;
				width: 100%;
			}

			.selfTrackboardCelldata {
				width: 100%;
				border: none;
				padding: 0;

				img {
					width: 16px;
					margin: 3px 0;
				}

				.notReceivedtxts {
					color: $danger;
				}

				.noSpendtxts {
					color: #858585;
				}

				.fileErrortxts {
					color: $warning-102;
				}
			}
		}
	}
}


.area {
	max-height: 150px;
	overflow-y: scroll;
  }
  
  .hide {
	background: red;
  }
  
  .show {
	background: green;
  }

  .selfTrackboardVendorIcons {
	  display: flex;
	  list-style: none;
	  justify-content: space-between;

	  li {
		  margin-left: 8px;
		  cursor: pointer;

		  .NotesCalloutpopup {
			width: 14px;
		  }
	  }
  }

  .FilereceivedPopup{
	padding: 0;
	width: 390px;
	border-radius: 10px;
  }

  .ms-Callout {
	  &:has( .FilereceivedPopup), &:has(.ReportTimelineCallout), &:has(.AddNotesPopup){
		box-shadow: rgba(0, 0, 0, 0.133) 2px 5px 14px -5px;

		.ms-Callout-beak {
			background-color: $secondary;
		}

		.ms-Callout-beakCurtain {
			border-radius: 10px;
		}

		.ms-Callout-main {
			border-radius: 10px;
		}
	  }
  }

  .callOutHeader {
	background-color: $secondary;
	text-transform: uppercase;
	font-size: 12px;
	color: #fff;
	line-height: inherit;
	padding: 10px;

	.closeIcon {
		width: 18px;
		position: absolute;
		right: 7px;
		cursor: pointer;
	}
  }
  

  .callOutContent {
	padding: 10px;

	&.ReportTimelineCallout {
		width: 340px;
	}

	&.NotesCallout {
		width: 340px;

		.InputBtns {
			float: right;
			height: 28px;
			min-width: 60px;
		}
	}
	
	.callOutContentInner{
		color: #000;
		font-size: 13px;

		.callOutContentInnerleft {
			float: left;

			.callOutInnerHeader {
				line-height: inherit;
				font-size: 13px;
				margin-bottom: 5px;
				font-family: $Typo-Bold, sans-serif;
			}			
		}

		.callOutContentInnerright {
			float: right;
			min-height: 40px;
			display: flex;
			align-items: center;		
		}

		.callOutContentHeader {
			font-size: 14px;
			font-family: $Typo-Bold, sans-serif;
		}

		.callOutMonthBlock{
			padding-left: 25px;
			position: relative;
			margin-bottom: 15px;

			.notReceivedMessageAlert {
				display: flex;
				align-items: center;
				min-height: 50px;

				.reminderLinkBlock {
					margin-left: auto;
				}
			}

			&.callOutMonthErrorBorder:before {
				content: "";
				background-image: url(../../assets/images/Icon_NotReceivedInfo.svg);
				background-repeat: no-repeat;
				background-size: 16px 16px;
				width: 20px;
				height: 20px;
				position: absolute;
				left: 3px;
				z-index: 99;
			}

			&.callOutMonthErrorBorder:after{
				border-left: 3px solid red;
				content: "";
				left: 10px;
				position: absolute;
				height:	107px;
				z-index: 9;
				top: 19px;
			}

			&.callOutMonthWarningBorder:before {
				content: "";
				background-image: url(../../assets/images/MonthPending.svg);
				background-repeat: no-repeat;
				background-size: 23px 21px;
				width: 23px;
				height: 23px;
				position: absolute;
				left: 0;
				z-index: 99;
			}

			&.callOutMonthWarningBorder:after{
				border-left: 3px solid #FBAC25;
				content: "";
				left: 10px;
				position: absolute;
				height: 105px;
				z-index: 9;
				top: 19px;
			}

			.PendingMessageAlert {
				display: flex;
				flex-direction: row;
				justify-content: space-between;
				
				.PendingMessageAlertBlk {
					border-right: 1px solid #C7DEF4;
					padding: 0 10px;

					&:first-child {
						padding-left: 0;
					}

					&:last-child {
						border-right: none;
						padding-right: 0;
					}

					.PendingMessageHead {
						font-family: $Typo-Bold, sans-serif;
						font-size: 12px;
						margin-bottom: 3px;

						.PendingMessageHeadImg {
							float: left;
						}

						.PendingMessageHeadtxts {
							float: left;
							margin-left: 5px;
							line-height: 16px;
						}
					}
				}

				.PendingMessagContent {
					font-size: 12px;

					p {
						margin-bottom: 2px;
					}
				}
			}
		}

		.callOutContentMonth {
			font-size: 13px;
			line-height: inherit;
			margin: 0 0 9px 4px;
		}

		.callOutContentInnerScroll {
			max-height: 400px;
			overflow: hidden;
			overflow-y: auto;
		}

		.callOutContentHead {
			border-left: 1px solid $neutral-1009;
			padding-left: 10px;
			margin-left: 5px;
		}
	}
  }

.reminderLink {
	font-size: 11px;
	text-decoration: underline;
	font-family: $Typo-Bold, sans-serif;
	cursor: pointer;
}

.AlertMessage {
	border: 1px solid;
	padding: 10px;
    border-radius: 8px;
	min-height: 55px;
}

.notReceivedMessageBorder {
	border-color: #DB2727;
	background-color: #DB27271A;
}

.infoReceivedBorder{
	border-color: #C7DEF4;
	background-color: #F8FAFE;
}

.FilentrecivdTxts {
	font-family: $Typo-Bold, sans-serif;
	font-size: 12px;
	color: $danger;

	 img {
		margin-right: 5px;
	    width: 12px;
		float: left;
		margin-top: 2px;
	 }
}

.SelfReportingContainer {
	position: relative;
}
.DropdownContainer {
	display: flex;
	position: relative;
	z-index: 999;

	.Dropdown {
		width: 200px;
		padding-right: 12px;

		.search-dropdown {

			.dropdown-container {
				border: 1px solid $primary-1000;

				&:focus-within {
					border: 1px solid #CAE48C;
					box-shadow: none;
				}

				.dropdown-heading {
					&:focus {
						border: 1px solid $primary-1000;
					}
				}

				.dropdown-content {
					padding-top: 0;

					.panel-content {
						border: 1px solid #CAE48C;
						box-shadow: none;

						.search {
							background: $light;

							input {
								&:focus {
									background: $light;
								}
							}
						}

						.item-renderer {
							align-items: center;
						}

						.select-item {
							border: 1px solid #CAE48C;

							&:hover {
								background: #F8FCEF;
							}
						}

						.select-item.selected {
							background: #F8FCEF;
						}

						.item-renderer input {
							accent-color: #0071cd;
							height: 15px;
							width: 15px;
						}
					}
				}
			}
		}

	}
}
.ToggleContainer {
	display: flex;
	align-items: center;
	float: right;
	position: relative;
	top: 27px;

	.Text {
		color: $primary;
		font-size: $rts-1;
		width: 80px;
	}

	.btn {
		width: 35px;
		height: 38px;
		float: left;
		font-size: 18px;
		background-color: $light;
		color: $neutral-400;
		border: 1px solid $neutral-400;
		padding: 6px 9px;
		border-radius: 4px;
		cursor: pointer;
	}

	.UpdateBtn {
		&:after {
			background: $neutral-400;
			content: "";
			height: 35px;
			position: absolute;
			left: 133px;
			top: 0;
			width: 1px;
			margin-right: 20px;
		}
	}

	.columnsStatus {
		margin-top: 12px;
		
		li {
			float: left;
			margin-right: 10px;
			list-style: none;

			@include desktop1280 {
				margin-right: 5px;
			} 
		}
	}
}

.fixedTopSection {
	position: fixed;
    height: 150px;
    width: 100%;
    z-index: 9999;
    margin-bottom: 30px;
    background: #F2F6F7;
    left: 0;
    padding: 8px 15px 16px;
	top: 120px;
}

.ToggleSwitchTxts {
	width: 140px;
}

.bottomGridSection {
	margin-top: 167px;

	.DropdownContainer{
		width: 100%;
		
		.Dropdown {
			width: 100%;
		}
	}
}

.SelfReportingDashboardContainer{
	.modalPopupMasterContainer{
		.modalPopupContainer{
			.modalBodyContainer{
				// overflow: visible;
				padding: 15px 15px 0 15px;
			}
		}

		&.sendEmailModal {
			label {
				text-align: right;
				padding-top: 10px;
				display: block;
				font-size: $rts-2;
			}
		}
	}
	.dropdown-content {
		.options {
			max-height: 150px;
		}
	}
	.ms-TooltipHost{
        display: block;
    }
}

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2);
	z-index: 99999;
	display: none; /* Initially hide the backdrop */
  }
  
  .backdrop.visible {
	display: block; /* Show the backdrop when the 'visible' class is present */
  }

.TopHeaderRow {
	display: flex;
	flex-direction: row;
	// min-width: 375px;
	border-right: 0 !important;
	border-bottom: 0 !important;
	padding: 0 !important;

	.TopHeaderRowCell{
		width: 100% !important;
		border: 0 !important;
		padding: 0 !important;
	}

	&.MonthFullWidth {
		min-width: 375px;
	}

	&.MonthHalfWidth {
		min-width: 250px;
	}

	&.MonthQuarterWidth {
		min-width: 125px;
	}
	
	&.MonthNoWidth {
		min-width: 0px;
	}
}

.messageField {
	.ms-TextField-wrapper {
		.ms-TextField-fieldGroup {
			min-height: 150px;
		}
	}
}



@include smalldesktop{
	.bottomGridSection {
		margin-top: 285px;
	}
}

@include tablet {
	.bottomGridSection {
		margin-top: 280px;
	}
}

@include mobiledevices {
	.bottomGridSection {
		margin-top: 325px;
	}

	.ToggleContainer{
		float: none;
	}

	.UpdateBtn {
		width: 215px;
	}

	.selfTrackboard {
		.FixedlftColumn {
			position: inherit;
		}
	} 

	.FilereceivedPopup{
		width: 320px;
	}
}