@import '../../../../commonStyles.scss';

.heading {
  color: $dark-100;
  font-family: $Typo-Bold;
  margin-bottom: 10px;
  font-size: $rts-1;
}

.cardBody {
    border: 1px solid $neutral-400;
    padding: 15px;
    border-radius: 4px;
}

.disableLink {
    opacity: 0.5;
    pointer-events: none;
    cursor: not-allowed;
}

.ka-input {
  border-color: $neutral-400;
  border-radius:4px;
  height:22px;
  width:100%;
}

.rebate-table {
  thead {
    tr {
      th:nth-child(5){
        &.ka-thead-cell {
          .plus-cell-button {
            position: absolute;
            top: 10px;
            left:85px;
            cursor: pointer;
            
            img {
              display: none;
            } 
            &::before {
              content:url('../../../assets/images/TierIcons/plus-icon.png');
            }
          }
        }
      }
    }
  }

  tbody {
    tr {
      td:nth-child(1) {
        .ka-input {
          display: none;
        }

      }
      
      td:nth-child(5) {
        position: relative;
        width:80px;

        .ka-cell-editor {
          .save-cell-button {
            position: absolute; 
            right:60%;
            top: 14px;
            cursor: pointer;
            img {
              display: none;
            } 
            &::before {
              content:url('../../../assets/images/TierIcons/save.svg')
            }
          }
          .close-cell-button {
            position: absolute; 
            right: 32%;
            top: 14px;
            cursor: pointer;
            img {
              display: none;
            } 
            &::before {
              content:url('../../../assets/images/TierIcons/close.svg')
            }
          }
        }

        .ka-cell-text {
          .button {
            .edit-cell-button{
              right: 60%;
              position: absolute;
              top: 6px;
              cursor: pointer;
              
              img {
                display: none;
              } 
              &::before {
                content:url('../../../assets/images/TierIcons/edit.svg');
              }
              @media (max-width: 1024px) {
                right: 85px;
              }
              @media (max-width: 1000px) {
                right: 75px;
              }
              @media (max-width: 768px) {
                right: 55px;
              }
              @media (max-width: 600px) {
                right: 15px;
              }
          }
            .delete-row-column-button{
              right: 32%;
              position: absolute;
              top: 6px;

              img {
                display: none;
              }
              &::before {
                content:url('../../../assets/images/TierIcons/delete.png');
              }
              @media (max-width: 768px) {
                right: 30px;
              }
              @media (max-width: 600px) {
                right: 5px;
              }
            }
          }
        }
      }
    }
  }
}

.requiredText {
  color: rgb(164, 38, 44);
  font-size: 12px;
}

.action-container {
  span {
    margin: 0 10px;
    img {
      cursor: pointer;
    }
  }
}

.grid-input {
  width: 100%;
  &::placeholder {
    color: #999;
    font-weight: normal;
  }
  input {
    border: none;
  }
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

input[type=number] {
  -webkit-appearance: none;
  -moz-appearance: textfield;
  appearance: none;
  margin: 0; 
}

.grid-search {
  .searchbox {
    width: 100%;
    .grid-input {
      background-color: $light;
      border: none;
      input {
        border: none;
      }
    }
  }
}

.disable-dropdown {
  pointer-events: none;
  opacity: 0.7;
  .search-dropdown{
    div{
      background-color: $neutral-1111;
      border: none;
    }
  }
  .multi-select{
    .dropdown-container{
      background-color: $neutral-1111;
      border: none;
      &[aria-disabled=true]:focus-within{
        box-shadow: none;
      }
      .dropdown-heading{
        pointer-events: initial;
      }
    }
  } 
}

.selected-products{
  .ms-TooltipHost{
    display: block;
  }
}

.selected-product-categories{
  .ms-TooltipHost{
    display: block;
  }
}

.selected-subcategories{
  .ms-TooltipHost{
    display: block;
  }
}

.ms-Tooltip-subtext{
  white-space: pre-line;
}

.loader-wrapper{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(77,77,77, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  
  .loading-spinner {
    width: 60px;
    height: 60px;
    position: relative;
    margin: 50px auto;
    border: 3px solid #f3f3f3;
    border-radius: 50%;
    border-top-color: #0071cd;
    animation: spin 1s linear infinite;
    z-index: 9999;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}

.ka-paging.ka-paging-sizes-active .page-size-block::before{
  pointer-events: none;
}

.check-received-radio-container {
      .ms-ChoiceFieldGroup {
          .ms-ChoiceFieldGroup-flexContainer {
              display: flex;
              .ms-ChoiceField {
                  margin-right: 20px;
                  margin-top: 0;
              }
          }
      }
}

.TierTable{
  .ka-thead-cell-content div::after{
    display: none;
  }
  tr:not(:last-child){
    td
    {
      .delete-row-column-button{
        display: none;
      }

      .edit-cell-button {
        left: 31%;
      }
    }
  }
}

.fa-info-circle{
    color: $primary;
}

.labelLeft{
  width: 50%;
  .ms-TooltipHost{
    float: left;
  }
}

.product-subcategories-labelLeft{
  width: 80%;
  .ms-TooltipHost{
    float: left;
  }
}

.product-categories-labelLeft{
  width: 80%;
  .ms-TooltipHost{
    float: left;
  }
}

.ShowEditProgram{
  display: block;
}

.HideEditProgram{
  display: none;
}

.multi-select{
  .dropdown-container{
    height: 34px;
    font-size: 12px;
    line-height: 13px;
    .dropdown-heading{
      height: 34px;
    }
  }
}

.StatusControl{
  .ms-Checkbox{
    .ms-Checkbox-text{
      line-height: 20px;
    }
  }
}

.add-edit-view-container-fieldset {
  border:0 none;
}


.calcType{
  clear: both;
  .calcTypeField {
    float:left;
    .ms-Dropdown-container {
      width: 346px;
    }
    @media (max-width: 600px) {
      .ms-Dropdown-container {
        width: 280px;
      }
    }
  }
  @media (max-width: 600px) {
    .calcTypeField {
      float: none;
      display: block;
      margin-bottom: 10px;
    }
  }
  .calcTypeFieldval {
    float: left;
    margin-left: 15px;
    
    &.DollarLabel {
      .monthSpinner {
        input {
          width: 120px;
        }
      }
    }
  }
  @media (max-width: 600px) {
    .calcTypeFieldval {
      float: none;
      display: block;
      margin-bottom: 10px;
      margin-left: 0;
    }
  }
}


.multi-select-width{
  .multi-select{
    .dropdown-container{
      width: 29rem;
    }
    @media (max-width: 992px) {
      .dropdown-container{
        width: 15rem;
      }
    }
    @media (max-width: 600px) {
      .dropdown-container{
        width: 20rem;
      }
    }
  }
}

.renew-contract-button {
  display: flex;
  padding-top: 25px;
  .ms-Checkbox-text {
    line-height: 19px;
  }
  .fa-info-circle{
    padding-top: 11px;
  }
  @media (max-width: 600px) {
    margin-top: 13px;
  }
}

.disable-dropdown [class*="singleValue"] {
  position: relative;
}

.showCheckboxSection {
  display: flex;
  justify-content: flex-end;
  flex-direction: row;

  .showCheckbox {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    
    .checkBoxLabel {
      font-family: $Typo-Bold;
      color: $black;
      font-size: $rts-1;
    }

    .ms-Checkbox {
      margin: 2px;

      .ms-Checkbox-checkbox {
        width: 16px;
        height: 16px;
      }
    }
  }
}
