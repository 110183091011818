@import '../../../commonStyles.scss';

.monthSpinner {
    display: flex;

    input {
        width: 60px;
        outline: none;
        border: 1px solid $primary-1000;
        padding: 5px;
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
        color: #333;
        font-size: $rts-1;
    }

    .spinner {
        display: flex;
        flex-direction: column;

        &.spinnerDisabled {
            pointer-events: none;
        }

        .arrowBtn {
            cursor: pointer;
            width: 35px;
            height: 16px;
            border: 1px solid $primary-1000;
            background-color: #0071CD0F;
            text-align: center;
            line-height: 13px;
            border-left: none;
            &:first-child {
                border-top-right-radius: 2px;
                border-bottom: none;
            }
            &:last-child {
                border-bottom-right-radius: 2px;
            }
        }
    }
}