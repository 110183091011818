@import '../../../../commonStyles.scss';

.UploadFileStatusContainer {
    .ms-TooltipHost{
        display: block;
    }
    .search-dropdown {

        .dropdown-container {
            border: 1px solid $primary-1000;

            &:focus-within {
                border: 1px solid #CAE48C;
                box-shadow: none;
            }

            .dropdown-heading {
                &:focus {
                    border: 1px solid $primary-1000;
                }
            }

            .dropdown-content {
                padding-top: 0;

                .panel-content {
                    border: 1px solid #CAE48C;
                    box-shadow: none;

                    .search {
                        background: $light;

                        input {
                            &:focus {
                                background: $light;
                            }
                        }
                    }

                    .item-renderer {
                        align-items: center;
                    }

                    .select-item {
                        border: 1px solid #CAE48C;

                        &:hover {
                            background: #F8FCEF;
                        }
                    }

                    .select-item.selected {
                        background: #F8FCEF;
                    }

                    .item-renderer input {
                        accent-color: #0071cd;
                        height: 15px;
                        width: 15px;
                    }
                }
            }
        }
    }

    .css-13cymwt-control {
        border: 1px solid $primary-1000;

        &:hover {
            border: 1px solid $primary-1000;
        }
    }

    .css-1u9des2-indicatorSeparator {
        display: none;
    }

    .updateBtn {
        margin-top: 27px;
    }
    
    .errorStatusTxts{
        font-size: $rts-1;
        color: $danger;
        font-family: $Typo-SemiBold;
    }
}
