@import "../../../commonStyles.scss";

.alert-msg {
    &.ms-MessageBar {
        padding: 5px 10px;
        border-radius: 4px;
        border: 1px solid #2d8ddb;
        background: #ebf5ff;
        color: #2d8ddb;

        .ms-MessageBar-dismissal{
            .ms-Button-icon {
                color:#2d8ddb;
                font-size: $rts-1;
                font-weight: bolder;
            }
        }

    &.ms-MessageBar--warning {
        border: 1px solid #b18907;
        background: #fff7cb;
        color: #b18907;

        .ms-MessageBar-dismissal{
            .ms-Button-icon {
                color:#b18907;
            }
        }
    }
    &.ms-MessageBar--success {
        border: 1px solid #5d8300;
        background: #ebf5d4;
        color: #5d8300;

        .ms-MessageBar-dismissal{
            .ms-Button-icon {
                color:#5d8300;
            }
        }
    }
    &.ms-MessageBar--error {
        border: 1px solid #ff0000;
        background: #ffe8e8;
        color: #ff0000;

        .ms-MessageBar-dismissal{
            .ms-Button-icon {
                color:#ff0000;
            }
        }
    }
    }
    
    .ms-MessageBar-icon {
        display:none;
    }
    .ms-MessageBar-innerText {
        font-size: $rts-3;
        font-family: $Typo-SemiBold;
    }
}
