@import "../../../commonStyles.scss";

.form-input {
  .ms-TextField-fieldGroup {
    height: 35px;
    border: 1px solid $primary-1000;
  }
}

.main-188 {
     border-radius: 10px;
}


.ms-Dialog-main {
  max-height: 1000px !important;
  min-width: 350px !important;
}

.ms-Dialog-subText {
    margin-bottom: 0;
}
.ms-Dialog-title {
    padding: 15px 15px 0px 15px !important;
	text-align: center;
}

.popup-content {
    text-align: center;

.close-btn-Popup {
    right: 0px;
    position: absolute;
    padding: 0;
    cursor: pointer;
    top: 0px;
}

.popup-title {
    color: $primary-100;
    font-size: 14px;
    font-weight: bold;
}

.warning {
    color: $warning-101;
    font-size: 14px;
}
}

.popup-subcontent {

    .popup-subtext {
        text-align: center;
        font-size: 12px; 
    }
    
    .popup-subtext-warning{
        text-align: center;
        font-size: 12px; 
    }

    .popupbtn {
        display: inline-block;
        width: 100%;
        text-align: center;
    }

    .popup-commentarea {
        padding-top: 10px;
    }

    .commentBox-Notshown{
        display: none;
    }

    button {
        width: 48%;
    }
    .singleBtnConfirmation {
        display: none;
    }

    .completed-report{
        display: flex;
        justify-content: space-between;
        border: 1px solid #E1E2E3;
        padding: 10px;
        background: #F7FAFD;
        border-radius: 5px;
        padding-top: 15px;
        font-size: 12px;

        .left-section{
            float: left;
            max-width: 169px;
            font-size: 10px;
        }
        .right-section {
            float: right ;
            text-align: end;
        }
        .fa-check-circle-o:before {
         color: $secondary;
        }
        .rejected-btn {
         color: red;
        }
        .returned-btn {
         color: rgb(18, 97, 208);
        }
    }

    .completed-report-nonactive{
        display: none;
    }

}
