@import "../../../commonStyles.scss";

.rc-tree-select-arrow::after{
    background-image: url('../../assets/images/Branch\ Location.svg') !important;
    background-repeat: no-repeat;
    border:none !important;
    height:20px !important;
    width:20px  !important;
    transform: rotate(0deg) !important; 
}
.rc-tree-select-selection {
    position: absolute  !important;
    top: -35px;
    right: 0;
    float: none;
    display: block;
    width: 32px;
    margin: 25px 7px;
    padding: 2px 6px 4px 6px;
    background-color: rgba(255, 255, 255,0.1) !important;
    border-color: $primary !important;
}
.rc-tree-select-enabled.rc-tree-select-focused .rc-tree-select-selection {
    border-color: $primary !important;
}
.rc-tree-select-tree li span.rc-tree-select-tree-switcher {
    height: 10px !important;
    width: 10px !important;
    background-color: #0071cd !important;
    transform: rotate(0deg);
    border: none !important;
    margin-right:0 !important;
    margin-bottom: 0 !important;
    line-height: 10px !important;
}
.rc-tree-select-tree-title {
    vertical-align: middle;
    font-size:11px;
}
.rc-tree-select-tree-node-selected {
    margin-left: 5px;
}
.rc-tree-select-tree-node-content-wrapper {
    padding-top:2px;
}
.rc-tree-select-dropdown {
    z-index: 9999999 !important;
    position: absolute!important;
    right: 22px;
    left: auto !important;
    top: 110px !important;
    min-width: 60% !important;
    border-radius: 0 !important;
    max-width:100%;
    li {
        position:relative;
        padding-bottom:10px;
    }
    li::before, li::after {
        content: "";
        position: absolute;
        left: 9px;
    }
    li::before {
        width: 14px;
        height: 0px;
        top: 13px;
        border-top: 1px solid #D8D8D8 !important;

    }
    li:first-child::before {
        top:34px;
    }
    li::after {
        border-left: 1px solid #D8D8D8;
        height: 100%;
        width: 0px;
        top: -5px;
        left:-9px;
    }

}

.rc-tree-select-dropdown ul li, .rc-tree-select-tree-child-tree .rc-tree-select-tree-treenode-switcher-close:first-child {
    margin-top: 0px !important;
}
.rc-tree-select-dropdown ul li ul li:before {
    width: 19px,
  }
  .rc-tree-select-dropdown ul li ul li ul li:before {
    width: 19px,
  }
  .rc-tree-select-dropdown ul > li:last-child::after {
    height:18px
  }
  .rc-tree-select-dropdown > ul > li:before {
    border-top: none !important
  }
  .rc-tree-select-dropdown > ul > li:after {
      height:0 !important
  }
.rc-tree-select-tree-treenode-selected .rc-tree-select-tree-child-tree {
    padding-top: 0px !important;
}
.rc-tree-select-tree-child-tree .rc-tree-select-tree-treenode-switcher-open:first-child, .rc-tree-select-tree-treenode-switcher-open:first-child, .rc-tree-select-tree-treenode-switcher-close:first-child {
    margin-top: 0px !important;
}