@import "../../../commonStyles.scss";

.myprofile-userprofile {
  .cancelUser {
    display: block;
  }
}
.header-wrapper {
  display: block;
  clear: both;
  height: 70px;
  // background-color: $primary;
  // color: $light;
  background-color: #fff;
  color: #5A5A5A;
  font-weight: 500;
  position: fixed;
  width: 100%;
  top: 0;
  z-index:99999;
  padding: 0 20px 0 10px;

  @include mobiledevices {
    height: 120px;
  }

  .headerlogo-section {
    margin: 17px 15px 1px;
    float: left;

    p {
      font-size: $rts-5;
      float: left;
      font-family: $Typo-Bold;
      margin-top: 24px;
      margin-left: 10px;
      margin-bottom: 0;
      // color: $light;
      color: #333333;
    }

    img {
      float: left;
      height: 38px;
    }
  }

  .userprofile-section {
    float: right;

     .branch-drpdown {
      margin: 15px 5px;
      width: 220px;
      font-size: 12px;
      float: left;
      height: 40px;
      background: #FFFFFF1A 0% 0% no-repeat padding-box;
      border: 1px solid #FFFFFF1A;
      padding: 6px 10px;
      text-align: left;
      line-height: 14px;
      font-family: $Typo-Regular;
      cursor:pointer;
      position: relative;

      .branch-hierarchy {
        font-size: 9px;
      }
      .branch-name {
        font-size: $rts-1;
      }
      .arrow-down {
        border: solid #fff;
        border-width: 0 2px 2px 0;
        display: inline-block;
        padding: 3px;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        position: absolute;
        right: 10px;
        top: 13px;
      }
     }
     .branch-dropdown {
      position: absolute;
      top: 40px;
      right: -2px;
      min-width: 217px;
      margin: 0;
      font-size: 1rem;
      text-align: left;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #e4e4e4;
      border-radius: 2px;
      z-index: 10001;
      box-shadow: 0px 3px 6px #00000029;

      &.locationDrpdown {
        color:#000;
        padding:10px;
        min-width:350px;

        .rct-icon-uncheck,.rct-icon-check {
          display: none;
        }
        .rct-node-parent.rct-node-expanded .rct-text {
          background: transparent;
          border: 1px solid transparent;
          color: #000;
        }
        .rct-node-parent li {
          padding:0  !important;
        }
        .react-checkbox-tree li {
          padding-bottom:0  !important;
        }
        .rct-text {
          padding:0 5px;
        }
        .bg-color {
          background-color: $primary;
          height: 15px;
          width: 15px;
          display: block;
          margin-top: 2px;
          margin-right: 5px;
        }
        .rct-title span {
          display: flex;
        }
      }

      .branch-card {
        clear: both;
        width: 200px;
        border: 1px solid $primary-1000;
        border-radius: 4px;
        height: 40px;
        padding: 5px 10px;
        display: inline-block;
        margin: 10px 10px -5px 10px;
      
        p {
          color:$black;
          font-family: $Typo-SemiBold;
          font-size: $rts-1;
          line-height:10px;
        }
        span {
          font-size: 11px;
          color: $neutral-1106;
        }

        &.active {
          border: 1px solid $primary-1000;
          background: $primary-1001 0% 0% no-repeat padding-box;
      
          .branch-section {
            position: relative;
      
            .branch-image {
              &::after {
                content: url("../../assets/images/Tick\ icon.svg");
                position: absolute;
                right: 0;
                top: 3px;
              }
            }
          }
        }
      
        &:nth-child(3n) {
          margin-right: 0;
        }
      
        img {
          vertical-align: sub;
        }
      }
    }

    .header-dropdown {
      margin: 17px 7px 0 0;
      float: left;

       .ms-Dropdown {
           border-radius: 4px 4px 0 0;
           height: 34px;
           width: 179px;

           .ms-Dropdown-title {
             border-color: rgba(255, 255, 255, 0.10)  !important;
             background-color: rgba(255, 255, 255, 0.10);
             color: $light;
             line-height: 28px;
             font-size: 11px;
             width: 179px;
           }

           .ms-Dropdown-caretDown {
             color: $light !important;
           }
       }
    }

    .custom-dropdown-menu {
      position: absolute;
      top: 45px;
      right: 5px;
      min-width: 120px;
      margin: 0;
      font-size: $rts-3;
      text-align: left;
      background-color: $light;
      background-clip: padding-box;
      border: 1px solid $neutral-200;
      border-radius: 4px;
      z-index: 999;
      box-shadow: 0px 3px 6px #00000029;

      .usernavname-dropdown {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;
        
        li {
          cursor: pointer;
        }
        .userprofile-detail {
          padding: 5px 15px;
          line-height: 10px;

          div {
            font-size: 10px;
            color: $neutral-700;
            font-family:$Typo-Bold
          }
          span {
            font-size: 8px;
            color: $neutral-700;
          }
        }

        > li {
          padding: 5px 10px;
          color: $dark-300;
          border-top: 1px solid $neutral-200;

          .user-dropdown-icon {
            position: relative;
            &::after {
              left: 0;
              bottom: 4px;
              position: absolute;
              height: 8px;
              width: 8px;
            }
  
            &.profile {
              &::after {
                content: url('../../assets/images/Profile\ Blue.svg');              
             }
            }

            &.branch {
              &::after {
                content: url('../../assets/images/Branch\ Blue.svg');              
             }
            }

            &.settings {
              &::after {
                content: url('../../assets/images/Settings\ Blue.svg');              
             }
            }

            &.logout {
              &::after {
                content: url('../../assets/images/Logout\ Blue.svg');              
             }
            }
          }
          a {
            text-decoration: none;
            color: $dark-200;
            font-size: 10px;
          }

          &:hover {
            background-color: $primary-600;

            .user-dropdown-icon {

              &.profile {
                &::after {
                  content: url('../../assets/images/Profile.svg');              
               }
              }
  
              &.branch {
                &::after {
                  content: url('../../assets/images/branch.svg');              
               }
              }
  
              &.settings {
                &::after {
                  content: url('../../assets/images/Settings.svg');              
               }
              }
  
              &.logout {
                &::after {
                  content: url('../../assets/images/logout.svg');              
               }
              }
            }
            a {
              color: $light;
            }
          }

          &:focus {
            background-color: $primary;
          }
        }
      }

      &::after,
      &::before {
        border: 10px solid transparent;
        position: absolute;
        content: "";
        right: 20px;
        bottom: 100%;
      }

      &::before {
        border-bottom-color: $neutral-1100;
        border-width: 5px;
        margin-right: -1px;
      }

      &::after {
        border-bottom-color: $light;
        border-width: 5px;
      }
    }

    .user-name {
      margin: 14px 0 5px 5px;
      float: left;
      position: relative;

      img {
        height: 38px;
        width: 38px;
        margin-right: 15px;
        border-radius: 50%;
      }

      .user-detail {
        float: left;
        margin: 7px 15px 8px 10px;

        div {
          font-family: $Typo-SemiBold;
          line-height: 12px;
        }

        span {
          font-size: $rts-1;
        }
      }

      .fa-caret-down {
        right: 0;
        position: absolute;
        top: 12px;
      }

      .setting-container {
        height: 40px;
        width: 40px;
        float: left;
        justify-content: center;
        align-items: center;
        display: flex;
        border: 1px solid #60606033;
        img {
          height: 19px;
          width: 19px;
          margin: 0;
        }
      }
    }
    
    .profile-image-block {
      float: left;
    }
    .settings-section {
      float: left;
      margin: 25px 0 15px 7px;

      img {
        height: 19px;
        width: 18px;
      }
    }

    .task-section {
        float: left;
        margin: 25px 7px;
  
        img {
          height: 16px;
          width: 13px;
        }
    }
      .notify-badge{
        background: #8bc400;
        color: #fff;
        font-size: 10px;
        width: 15px;
        height: 15px;
        display: inline-block;
        border-radius: 50%;
        text-align: center;
        position: absolute;
        right: -5px;
        top: -7px;
    }
    .notification{
        position:relative;
    }

    .cart-section {
        float: left;
        margin: 25px 7px;
  
        img {
          height: 16px;
          width: 22px;
        }
    }
    .location-section {
      display:none;
    }
    .hamburger-section {
        float: left;
        margin: 25px 20px 25px 0;
        display:none;
        img {
          height: 9px;
          width: 15px;
        }
    }
  }

  .notification-block {
    float: left;
    position: relative;
    margin: 25px 7px;

    img {
      height: 14px;
      width: 16px;
    }
  }

  .span-count {
    font-weight: 500;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 4px;
    right: 3px;
    background: $danger;
    border-radius: 100%;
    text-align: center;
    color: $light;
    line-height: 16px;
    font-size: 9px;
    font-family: $Typo-Regular;
  }

  .location-dropdown {
    display:none;
  }
}
@include mobiledevices {
  .header-wrapper {
    .headerlogo-section {
      padding: 15px 0;
      float: none;
      margin: 0 15px;
    }
    .userprofile-section {
      position: relative;
      float: none;
      margin: 0 15px;
      .branch-drpdown {
        display: none;
      }
       img {
         height:20px  !important;
         width: 16px  !important;
       }
      .header-dropdown {
        float:left;
        margin: 17px 55px;
        display: none;
        .ms-Dropdown, .ms-Dropdown-title {
          color: #656565 !important;
          width: 237px !important;
          background: #fff;
          opacity: 1;
          border-radius: 0;

          .ms-Dropdown-caretDown {
            color: #656565 !important;
          }
        }
      }
      .user-name {
        display: none;
      }
      .notification-block {
        position: absolute;
        top: -20px;
        right: 60px;
        float: none;
      }
      .cart-section {
        position: absolute;
        top: -20px;
        right: 30px;
        float: none;
      }
      .hamburger-section {
        position: absolute;
        top: -20px;
        right: 0px;
        float: none;
        margin: 25px 0;
        display: block;
      }
      .location-section {
        position: absolute;
        top: 22px;
        right: 0;
        float: none;
        display:block;
        margin: 25px 7px;
        background: rgba(255, 255, 255, 0.1);
        padding: 2px 6px 4px 6px;
      }
      .task-section {
        position: absolute;
        top: 22px;
        right: 40px;
        float: none;
        background: rgba(255, 255, 255, 0.1);
        padding: 2px 6px 4px 6px;
      }
    }
  }
  .location-dropdown {
    display:block  !important;
  }
}
.headText {
  font-size: 14px !important;
  margin: 18px 5px  !important;
  opacity: 0.9;
}