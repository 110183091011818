@import '../../../../commonStyles.scss';

.supplier-entry-container {
    .supplier-entry-modal-container {
        .ms-ChoiceFieldGroup {
            .ms-ChoiceFieldGroup-flexContainer {
                display: flex;
                .ms-ChoiceField {
                    margin-right: 20px;
                    margin-top: 0;
                }
            }
        }
        .abNumberDropdown{
            .dropdown-container[aria-disabled="true"]{
                 background-color: $neutral-100;
                 border-color: #e6e6e6;
                 &:focus-within{
                    box-shadow: none;
                 }
            }
        }
        .multi-select{
            .dropdown-container{
              width: 290px;
            }
          }
        .PrimaryABnumberDiv{
            position: relative;
            .PrimaryABnumbercheckbox{
                position: absolute;
                right: 12px;
            }
            .ms-Checkbox-checkbox{
                width: 16px;
                height: 16px;
            }
            .ms-Checkbox-text {
                font-size: 12px;
            }
        }
    }
}
.error-message {
    animation-name: css-0, css-13;
    animation-duration: 0.367s;
    animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
    animation-fill-mode: both;
    font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    font-weight: 400;
    color: rgb(164, 38, 44);
    margin: 0px;
    padding-top: 5px;
    display: flex;
    align-items: center;
}
.suppliermodal {
    margin-top: 15px;
    @include mobiledevices {
        margin-top:10px;
    }
}

.primaryABLink {
    font-size: $rts-1;
}

.primaryAbNumberLft {
    width: 85%;
}

.primaryAbNumberRgt {
    width: 11%;

    .primaryAbNumberRgtBox {
        border: 1px solid $neutral-1009;
        padding: 8px 9px;
        border-radius: 4px;
        cursor: pointer;

        .fa {
            font-size: $rts-4;
        }
    }
}

.abNumberDropdown {
    .dropdown-content {
      .options > label {
        &.select-item{
          display: none;
        }
      }
    }
  }

.modalPopupMasterContainer {
    &.vendorEntryPopup {
        .form-checkbox {
            .ms-Checkbox-text {
                line-height: 19px;
            }
        }

        .rmsc {
            .spinner {
                .path {
                    stroke: #0071cd;
                }
            }
        }

        .modalPopupFooter {
            position: absolute;
            bottom: 0;
            right: 0;
            width: 300px;
        }
    
        .search-dropdown {
            div [class$="loadingIndicator"] {
                display: inline-block;
                width: 24px;
                height: 24px;
                border: 3px solid rgba(255,255,255,.3);
                border-radius: 50%;
                border-top-color: #0071cd;
                animation: spin 1s ease-in-out infinite;
                -webkit-animation: spin 1s ease-in-out infinite;
    
                span {
                    display: none;
                }
            }
        }
    
        @keyframes spin {
            to {
                -webkit-transform: rotate(360deg);
            }
        }
    
        @-webkit-keyframes spin {
            to {
                -webkit-transform: rotate(360deg);
            }
        }
    }
}