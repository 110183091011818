@import "../../../../../commonStyles.scss";

.heading {
  color: $dark-100;
  font-family: $Typo-Bold;
  margin-bottom: 10px;
  font-size: $rts-1;
}

.rightTools {
  display: flex;

  .uploadBlock {
    @include mobiledevices {
      width: 100%;
      margin-bottom: 10px;
    }
  }

  @include tablet {
    flex-wrap: wrap;
  }

  @include mobiledevices {
    float: none;
    width: 100%;
    margin: 10px 0;
  }
}

.bulkUpload {
  font-size: $rts-1;
  margin-top: 10px;
}

.file-upload-col {
  .file-name {
    margin-top: 6px !important;

    .badgebox {
      margin: 0 1px;
    }
  }
}

.file-upload-success {
  color: #5d8300;
}

.file-upload-error {
  color: #ff0000;
}
