@import "../../../CommonStyles.scss";

.modal-open {
  overflow-y: hidden;
}

.modalPopupMasterContainer {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 105555;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;

  .dropShadowcontainer {
    height: 100%;
    width: 100%;
    float: left;
    background-color: #222;
    position: fixed;
    min-height: 200px;
    z-index: 99;
    border: 1px solid $neutral-700;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.5;
    pointer-events: all;
  }

  .modalPopupContainer {
    position: relative;
    background-color: $light;
    width: auto;
    z-index: 999;
    display: grid;
    border: 0;
    margin: 3.5rem auto;
    border-radius: 10px;

    &.large {
      width: 925px;

      .modalBodyContainer {
        width: 925px;
      }
    }

    &.small {
		top: 20%;
      width: 335px;
    }

    &.smaller {
      top: 10%;
      width: 450px;
    }

    &.medium {
		top: 10%;
      width: 612px;
    }
    &.extramedium{
      width: 700px;
    }
    .modalPopupHeader {
      background-color: $secondary;
      float: left;
      width: 100%;
      height: 40px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    .modalTitle {
      color: $light;
      font-size: $rts-1;
      margin-top: 10px;
      margin-left: 15px;
      float: left;
      font-family: $Typo-SemiBold;
      text-transform: capitalize;
    }

    .modalCloseIcon {
      color: $light;
      margin-top: 8px;
      margin-right: 15px;
      float: right;
      cursor: pointer;

      img {
        cursor: pointer;
        width: 13px;
        height: 13px;
      }
    }

    .modalPopupBody {
      box-sizing: border-box;
      float: left;
      width: 100%;
    }

    .modalBodyContainer {
      padding: 15px;
      margin-top: -5px;
      background-color: $light;
      border: 1px solid $light;
      border-radius: 10px;
    }

    .modalPopupFooter {
      background-color: $light;
      float: left;
      width: 100%;
      padding: 15px;
      box-sizing: border-box;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;

      .footerButton {
        float: right;
      }
    }
  }
}
.ConfirmationPopup{
  .modalCloseIcon{
    display: none;
  }
}
@include tablet {
  .modalPopupMasterContainer {
    .modalPopupContainer {
      &.large {
        width: 600px;
      }

	  &.small {
        width: 335px;
      }
    }
  }
}

@include mobiledevices {
  .modalPopupMasterContainer {
    .modalPopupContainer {
      &.large {
        width: 94%;
      }

      &.small {
        width: 94%;
      }
    }
  }
}
